import styled from 'styled-components';
import { ExpandMore } from '@mui/icons-material';

import { H5 } from '@/core/components/typography';
import { spacing } from '@/core/styles/mixins/spacing';

export const SelectWrapper = styled.div`
  width: 100%;
  margin-bottom: ${spacing(2.75)};
  padding: 0 ${spacing(2)};
`;

export const StyledSelect = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 48px;
  padding: 0 ${spacing(2)};
  background-color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
`;

export const Label = styled(H5)`
  margin-bottom: ${spacing(1)};
  color: ${({ theme }) => theme.colors.white};
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.32px;
`;

type ExpandMoreArrowProps = {
  isExpanded: boolean;
};

export const ExpandMoreArrow = styled(ExpandMore)<ExpandMoreArrowProps>`
  transform: ${({ isExpanded }) => (isExpanded ? 'rotate(180deg)' : 'rotate(0deg)')};
  pointer-events: none;

  && {
    transition: transform 0.1s ease-out;
  }
`;
