import {
  colors,
  customMultiselectColors,
  semanticColors,
} from './colors';
import { typography } from './typography';
import { spacing } from './spacing';

export const theme = {
  colors,
  semanticColors,
  customMultiselectColors,
  typography,
  spacing,
} as const;
