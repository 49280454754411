import styled from "styled-components";

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-height: var(--app-height);
`;

export const SidebarMainWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: stretch;
`;

export const StyledMain = styled.main<{ $ignoreMaxWidth?: boolean }>`
  flex: 1;
  max-width: ${({$ignoreMaxWidth}) => $ignoreMaxWidth ? "inherit" : 'calc(100% - 256px)'};
`;
