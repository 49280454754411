import styled from 'styled-components';

import {
  bodyText,
  fontWeight,
} from '@/core/styles/mixins/typography';
import { spacing } from '@/core/styles/mixins/spacing';

export const Title = styled.span`
  ${fontWeight('light')}

  font-size: 54px;
  line-height: 64px;
  letter-spacing: 0;
`;

export type HeadingProps = {
  topSpacing?: number;
  bottomSpacing?: number;
}

export const H1 = styled.h1<HeadingProps>`
  ${fontWeight('light')}

  margin-top: ${({ topSpacing }) => (topSpacing ? spacing(topSpacing) : 'initial')};
  margin-bottom: ${({ bottomSpacing }) => (bottomSpacing ? spacing(bottomSpacing) : 'initial')};
  font-size: 42px;
  line-height: 50px;
  letter-spacing: 0;
`;

export const H2 = styled.h2<HeadingProps>`
  margin-top: ${({ topSpacing }) => (topSpacing ? spacing(topSpacing) : 'initial')};
  margin-bottom: ${({ bottomSpacing }) => (bottomSpacing ? spacing(bottomSpacing) : 'initial')};
  font-size: 32px;
  line-height: 40px;
  letter-spacing: 0;
`;

export const H3 = styled.h3<HeadingProps>`
  ${fontWeight('regular')}

  margin-top: ${({ topSpacing }) => (topSpacing ? spacing(topSpacing) : 'initial')};
  margin-bottom: ${({ bottomSpacing }) => (bottomSpacing ? spacing(bottomSpacing) : 'initial')};
  font-size: 28px;
  line-height: 36px;
  letter-spacing: 0;
`;

export const H4 = styled.h4<HeadingProps>`
  ${fontWeight('regular')}

  margin-top: ${({ topSpacing }) => (topSpacing ? spacing(topSpacing) : 'initial')};
  margin-bottom: ${({ bottomSpacing }) => (bottomSpacing ? spacing(bottomSpacing) : 'initial')};
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0;
`;

export const H5 = styled.h5<HeadingProps>`
  ${fontWeight('semiBold')}

  margin-top: ${({ topSpacing }) => (topSpacing ? spacing(topSpacing) : 'initial')};
  margin-bottom: ${({ bottomSpacing }) => (bottomSpacing ? spacing(bottomSpacing) : 'initial')};
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0;
`;

export const H6 = styled.h6<HeadingProps>`
  ${fontWeight('semiBold')}
  ${bodyText}

  margin-top: ${({ topSpacing }) => (topSpacing ? spacing(topSpacing) : 'initial')};
  margin-bottom: ${({ bottomSpacing }) => (bottomSpacing ? spacing(bottomSpacing) : 'initial')};
`;
