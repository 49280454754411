import styled from 'styled-components';

import { bodyText } from '@/core/styles/mixins/typography';

type StyledContainerProps = {
  hasAlternateColor?: boolean;
}

export const StyledContainer = styled.div<StyledContainerProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.semanticColors.ui[6]};
  padding: 15px;
  background-color:
    ${({
    theme,
    hasAlternateColor,
  }) => (hasAlternateColor ? theme.colors.navbarDarkBlue : theme.semanticColors.ui[5])};

  color: ${({ theme }) => theme.semanticColors.text.inverse.primary};
`;

export const StyledLink = styled.a`
  ${bodyText}

  margin-right: 31px;
  padding-bottom: 4px;
  color: ${({ theme }) => theme.semanticColors.ui[3]};
  text-decoration: none;

  &:hover,
  &:active {
    border-bottom: 3px solid ${({ theme }) => theme.semanticColors.ui[3]};
  }
`;
