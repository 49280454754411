/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { createSelector } from 'reselect';

import { RootState } from '@/core/interfaces/store';

export const selectUser = (state: RootState) => state?.config?.user;
export const selectLobId = (state: RootState) => state?.config?.user.data?.lobId;
export const selectUserEmail = createSelector(selectUser, user => user?.data!.email);

