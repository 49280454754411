import { createGlobalStyle } from 'styled-components';

import {
  bodyText,
  fontFamily,
  fontWeight,
} from '@/core/styles/mixins/typography';

import { Theme } from './themes';

export const GlobalStyle = createGlobalStyle<{ theme: Theme }>`
  :root {
    /* window height fix for Safari */
    --app-height: 100vh;
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  body {
    ${bodyText}

    ${fontFamily('body')}
    ${fontWeight('regular')}

    margin: 0;
    background-color: ${({ theme }) => theme.semanticColors.ui.background};
    color: ${({ theme }) => theme.semanticColors.text.primary};
  }

  p {
    margin: 0;
  }

  button,
  input,
  textarea {
    border: none;
  }

  button {
    background: none;
  }

  input,
  textarea {
    color: ${({ theme }) => theme.semanticColors.text.primary};
  }
`;
