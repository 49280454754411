import styled from 'styled-components';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Skeleton,
} from '@mui/material';

import { fontSize } from '@/core/styles/mixins/typography';
import { spacing } from '@/core/styles/mixins/spacing';
import { Divider } from '@/core/components/Divider/Divider';

export const Container = styled.div`
  flex: 0 0 256px;
  width: 256px;
  border-right: 1px solid ${({ theme }) => theme.semanticColors.ui[6]};
  padding-top: 16px;
  background-color: ${({ theme }) => theme.semanticColors.ui[5]};
  color: ${({ theme }) => theme.semanticColors.text.inverse.secondary};
`;

export const MenuList = styled.ul`
  margin: 0;
  padding: 0;
`;

export type MenuItemProps = {
  isActive?: boolean;
}

export const MenuItem = styled.li<MenuItemProps>`
  display: flex;
  align-items: center;
  width: 100%;
  height: 32px;
  margin: 0;
  padding: 0 16px;
  background-color:
    ${({
    theme,
    isActive,
  }) => (isActive ? theme.semanticColors.active.primary : 'transparent')};

  &:hover {
    background-color:
      ${({
    theme,
    isActive,
  }) => (isActive ? theme.semanticColors.hover.primary : theme.semanticColors.hover.secondary)};
  }
`;

export const IconContainer = styled.span`
  ${fontSize('bigger')}

  display: inline-block;
  width: 16px;
  margin-right: ${spacing(2)};
  line-height: 14px;
`;

export const Link = styled.a`
  color: ${({ theme }) => theme.semanticColors.text.interactive};
  font-weight: ${({ theme }) => theme.typography.fontWeights.semiBold};
  line-height: 18px;
  text-decoration: none;
`;

export const StyledAccordion = styled(Accordion)`
  && {
    background: none;
  }

  &::before {
    display: none;
  }
`;

export const StyledAccordionDetails = styled(AccordionDetails)`
  flex-direction: row-reverse;

  && {
    padding: 0;
  }
`;

export const StyledAccordionSummary = styled(AccordionSummary)`
  && {
    min-height: ${spacing(4)};
  }

  & .MuiAccordionSummary-expandIconWrapper {
    color: ${({ theme }) => theme.semanticColors.text.inverse.primary};
  }

  & .MuiAccordionSummary-content {
    margin: 0;
    color: ${({ theme }) => theme.semanticColors.text.inverse.primary};
  }
`;

export const AccordionMenuItem = styled(MenuItem)`
  padding: 0;
  background-color: transparent;
  font-weight: ${({ theme }) => theme.typography.fontWeights.semiBold};

  &:hover {
    background: none;
  }
`;

export const AccordionMenuSubItem = styled(MenuItem)`
  padding-left: ${spacing(8)};
  font-weight: ${({ theme }) => theme.typography.fontWeights.regular};
`;

export const StyledDivider = styled(Divider)`
  width: auto;
  margin-right: ${spacing(2)};
  margin-left: ${spacing(2)};
`;

export const NonAccordionItem = styled.div<MenuItemProps>`
  height: ${spacing(4)};
  padding-right: ${spacing(2)};
  padding-left: ${spacing(2)};
  background-color:
    ${({
    theme,
    isActive,
  }) => (isActive ? theme.semanticColors.active.primary : 'transparent')};

  &:hover {
    background-color:
      ${({
    theme,
    isActive,
  }) => (isActive ? theme.semanticColors.hover.primary : theme.semanticColors.hover.secondary)};
  }
`;

export const StyledSkeleton = styled(Skeleton)`
  &.MuiSkeleton-root {
    width: 100%;
    background-color: ${({ theme }) => theme.colors.white};
  }
`;
