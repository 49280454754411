import { useRouter } from 'next/router';
import {
  useDispatch, useSelector,
} from 'react-redux';

import {
  getLineOfBusinessContent,
  getSelectedLineOfBusiness,
  LinesOfBusinessContent,
} from '@/core/store/reducers/staticContent';

import { selectUser } from '@/features/Config/store/selectors';
import { updateLobId } from '@/features/Config/store/actions';

const useAuthWrapperHook = () => {
  const dispatch = useDispatch();

  const user = useSelector(selectUser);
  const router = useRouter();
  const queryLobId = router.query.lobId;

  const fetchLobData = () => {
    if (!user?.data?.email) return;

    dispatch(getLineOfBusinessContent(
      user?.data?.email
    ) as Awaited<any>)
      .then(({ data }: Omit<LinesOfBusinessContent, 'message'>) => {
        const defaultSelectedLob = data
          ?.find(lineOfbusiness => String(lineOfbusiness.lobId) === queryLobId) || data?.[0];

        dispatch(getSelectedLineOfBusiness(defaultSelectedLob));
        dispatch(updateLobId(defaultSelectedLob?.lobId));
      })
      .catch((err: any) => console.error(err));
  };

  return { fetchLobData };
};

export default useAuthWrapperHook;
