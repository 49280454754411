import React from 'react';
import Link from 'next/link';

import { PillOptions } from '@/core/components/Pill/constants';
import { lineOfBusinessURL } from '@/core/constants/constants';

import {
  HeaderText,
  LineWrapper,
  LinkWrapper,
  Number,
  StyledPill,
  Text,
  Wrapper,
} from '@/features/MultiLine/components/Content/ContentItem/Line/Line.styles';

type Props = {
  featureName: string;
  alertsCount: number;
  claimsCount: number;
}

const Line = ({
  featureName, alertsCount, claimsCount,
}: Props) => (
  <LineWrapper>
    <HeaderText>{featureName}</HeaderText>
    <Wrapper>
      {alertsCount && claimsCount ?
        (
          <>
            <StyledPill variant={PillOptions.ALERT}>
              {alertsCount}
              {' '}
              alerts
            </StyledPill>
            in
            <Number>{claimsCount}</Number>
            {claimsCount > 1 ? 'claims' : 'claim'}
          </>
        ) :
        (<div>No alerts</div>) }

    </Wrapper>
    <LinkWrapper>
      <Link
        passHref
        href={lineOfBusinessURL[featureName as keyof typeof lineOfBusinessURL]}
      >
        <LinkWrapper $isPointer>
          <Text>Open</Text>

          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.99996 2.66699L7.05663 3.61033L10.78 7.33366H2.66663V8.66699H10.78L7.05663 12.3903L7.99996 13.3337L13.3333 8.00033L7.99996 2.66699Z"
              fill="#005A9E"
            />
          </svg>
        </LinkWrapper>
      </Link>
    </LinkWrapper>
  </LineWrapper>
);

export { Line };
