import React, { useEffect } from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { CircularProgress } from '@mui/material';

import {
  addLoader,
  removeLoader,
  selectLoadersCount,
} from '@/core/store/reducers/ui';

import {
  StyledContainer,
  StyledOverlay,
  StyledText,
} from './Loader.styles';

export type LoaderProps = {
  isAlwaysVisible?: boolean;
}

export const Loader = ({
  isAlwaysVisible = false,
}: LoaderProps) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isAlwaysVisible) {
      dispatch(addLoader());
    }

    return () => {
      if (!isAlwaysVisible) {
        dispatch(removeLoader());
      }
    };
  }, [isAlwaysVisible]);

  const loadersCount = useSelector(selectLoadersCount);

  return loadersCount < 3 || isAlwaysVisible ?
    (
      <StyledOverlay>
        <StyledContainer>
          <CircularProgress
            color="inherit"
            size={16}
            thickness={6}
          />
          <StyledText>Content is loading</StyledText>
        </StyledContainer>
      </StyledOverlay>
    ) :
    null;
};
