import React from 'react';
import Link from 'next/link';
import { LogoutOutlined } from '@mui/icons-material';

import {
  StyledContainer,
  StyledIconLink,
} from '@/core/components/Header/Header.styles';
import { Image } from '@/core/components/Image';

import { SignOutButton } from './SignOutButton';

const LOGOUT_URL = process.env.NEXT_PUBLIC_LOGOUT_URL;

export const Header = () => (
  <StyledContainer>
    <Link href="/">
      <a>
        <Image
          src="/images/icons/logo.svg"
          alt="Milliman Nodal"
        />
      </a>
    </Link>
    <SignOutButton />
  </StyledContainer>
);
