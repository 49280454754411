import {
  ReactNode,
  useEffect,
} from 'react';
import { useIsAuthenticated } from '@azure/msal-react';

import { Header } from '@/core/components/Header';
import { Sidebar } from '@/core/components/Sidebar';
import { Footer } from '@/core/components/Footer';

import {
  PageWrapper,
  SidebarMainWrapper,
  StyledMain,
} from './Layout.styles';

export type LayoutProps = {
  children: ReactNode;
  isSidebarShown?: boolean;
};

export const Layout = ({
  children,
  isSidebarShown,
}: LayoutProps) => {
  // window height fix for Safari
  useEffect(() => {
    const appHeight = () => {
      const doc = document.documentElement;

      doc.style.setProperty('--app-height', `${window.innerHeight}px`);
    };

    window.addEventListener('resize', appHeight);
    appHeight();

    return () => {
      window.removeEventListener('resize', appHeight);
    };
  }, []);

  const isAuthenticated = useIsAuthenticated();

  return (
    isAuthenticated ?
      (
        <PageWrapper>
          <Header />
          <SidebarMainWrapper>
            <Sidebar />
            <StyledMain>
              {children}
            </StyledMain>
          </SidebarMainWrapper>
          <Footer />
        </PageWrapper>
      ) :
      (
        <PageWrapper>
          <SidebarMainWrapper>
            <StyledMain $ignoreMaxWidth>
              {children}
            </StyledMain>
          </SidebarMainWrapper>
          <Footer hasAlternateColor />
        </PageWrapper>
      )
  );
};
