import dayjs from 'dayjs';
import Highcharts, { SeriesOptionsType } from 'highcharts';

import { FeatureNames } from '@/core/interfaces/store';
import { SHORT_DATE_FORMAT } from '@/core/constants/constants';

import { addCommas } from '@/utils/addCommas';

import { colors } from '@/themes/colors';

export const isBrowser = !!(typeof window !== 'undefined' && window.document && window.document.createElement);

export const getActionPrefix = (featureName: FeatureNames) => featureName.toUpperCase();

export const omitKeysWithNoValue = (object: { [key: string]: any } = {}) => (
  Object.entries(object).reduce((
    accumulator,
    current
  ) => {
    const [
      key,
      value,
    ] = current;

    return ({
      ...accumulator,
      ...(value === undefined || value === null || value === '') ? {} : { [key]: value },
    });
  }, {})
);

export const formatCurrency =
  (value?: number | string, options?: ShowValueOrSymbolOptions): string | number => {
    if (typeof value === 'undefined' || Number.isNaN(Number(value))) {
      return showValueOrSymbol(undefined, options);
    }

    const dollarUSLocale = Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });

    return dollarUSLocale.format(Math.round(Number(value)));
  };

export type ShowValueOrSymbolOptions = {
  isDate?: boolean;
  symbol?: string;
}

export const showValueOrSymbol = (
  value?: string | number,
  options: ShowValueOrSymbolOptions = {}
) => {
  const {
    isDate = false,
    symbol = '-',
  } = options;

  if (!value) {
    return symbol;
  }

  if (isDate && dayjs(value).isValid()) {
    return dayjs(value).format(SHORT_DATE_FORMAT);
  }

  return value;
};

export const showYesNoValue = (value?: number | string | boolean) => {
  if (typeof value === 'undefined' || value === null) {
    return '-';
  }

  return Number(value) === 0 ? 'No' : 'Yes';
};

export const formatOrdinalNumber = (number: number) => {
  const suffixes = [
    'th',
    'st',
    'nd',
    'rd',
  ];
  const remainderFrom100 = number % 100;

  return `${number}${(suffixes[(remainderFrom100 - 20) % 10] || suffixes[remainderFrom100] || suffixes[0])}`;
};

export const nonDigitsRegexp = /[^0-9]/g;

export const fixedValue = (value: number | string, fixed: number | undefined = 0) => (
  parseFloat(String(value)).toFixed(fixed)
);

export const percentageValue = (value: number) => `${Math.round(Math.abs(value) * 100)}%`;

export const currencyValue = (value: number) => (value >= 0 ?
  `$${addCommas(String(fixedValue(value)))}` :
  `-$${addCommas(String(fixedValue(Math.abs(value))))}`);

export const ratingValue = (value: number) => (value >= 0 ?
  `+${String(fixedValue(value, 1))}` :
  `${String(fixedValue(value, 1))}`);

export const distanceValue = (value: number) => (value ? `${String(fixedValue(value, 1))} Miles` : '-');

export const formatNumber = (value: number) => {
  const parts = value.toString().split('.');

  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  return parts.join('.');
};

export const dayValue = (value: number) => (value ? `${String(fixedValue(value, 0))} Days` : '-');

export const yearsValue = (value: number) => (value > 1 ? `${value} years old` : `${value} year old`);

export const areNumbersEqual = (
  value: number,
  value2: number,
  fixed = 1
) => Number(fixedValue(value, fixed)) === Number(fixedValue(value2, fixed));

export const getAllUsaStates = [
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'DC',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
] as const;

export const usaStateBy2LattersCode = {
  AL: 'Alabama',
  AK: 'Alaska',
  AS: 'American Samoa',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District Of Columbia',
  FM: 'Federated States Of Micronesia',
  FL: 'Florida',
  GA: 'Georgia',
  GU: 'Guam',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MH: 'Marshall Islands',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  MP: 'Northern Mariana Islands',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PW: 'Palau',
  PA: 'Pennsylvania',
  PR: 'Puerto Rico',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VI: 'Virgin Islands',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
} as const;

export const calculateInterval = (num: number) => {
  const lengthOfNumber = String(num).length;

  return 10 ** (lengthOfNumber - 1);
};

export const getKeyByValue = (
  object: { [key: string]: string },
  value: string
) => Object.keys(object).find(key => object[key] === value);

// for more info check https://merixstudio.atlassian.net/browse/MLN-1225
export const chartColors = [
  colors.vibrantBlue,
  colors.emerald,
  colors.gold,
  colors.cranberry,
  colors.skyBlue,
] as const;

// assign color to be one from chartColors
export const getChartSeriesObjectWithColors = (seriesObject: Array<SeriesOptionsType>) => {
  seriesObject.forEach((object, index) => {
    object.color = chartColors[index];
  });

  return seriesObject;
};

export type ValueOf<T> = T[keyof T];

export const FILE_TIMESTAMP_FORMAT = 'DD-MM-YYYY_hh_mm_ss';

export const DefaultItemsPerPage = 20;

export const formatDays = (days: number) => {
  const years = Math.floor(days / 365);
  const remainingDays = days % 365;

  const yearsPrefix = years ? `${years}y ` : '';

  return `${yearsPrefix}${remainingDays}d`;
};

export const getFileTimestamp = () => dayjs().format(FILE_TIMESTAMP_FORMAT);

export const createQueryParamFromArrayOfStrings = (array: Array<string>) => {
  const searchParams = new URLSearchParams({ params: array.join(',') });

  return searchParams.get('params');
};
