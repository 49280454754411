import styled, { css } from 'styled-components';

import { spacing } from '@/core/styles/mixins/spacing';
import {
  fontWeight,
  helperText,
} from '@/core/styles/mixins/typography';

const variants = {
  alert: css`
    ${fontWeight('semiBold')}

    border-radius: 16px;
    padding: ${spacing(0.5)} ${spacing(2)};
    background-color: ${({ theme }) => theme.semanticColors.support.tint01};
    color: ${({ theme }) => theme.semanticColors.support.danger};
  `,
  risk: css`
    ${helperText}

    border-radius: 12px;
    padding: ${spacing(0.5)} ${spacing(1)};
    background-color: ${({ theme }) => theme.semanticColors.support.tint};
    color: ${({ theme }) => theme.semanticColors.text.primary};
  `,
  tag: css`
    ${helperText}

    border-radius: 12px;
    padding: ${spacing(0.5)} ${spacing(2)};
    background-color: ${({ theme }) => theme.colors.pine20};
    color: ${({ theme }) => theme.semanticColors.text.primary};
  `,
};

export type PillProps = {
  variant: keyof typeof variants;
}

export const StyledPill = styled.span<PillProps>`
  display: inline-block;
  margin: 0 ${spacing(2)} ${spacing(1)} 0;

  ${({ variant }) => variants[variant]}
`;
