import React from 'react';
import { useMsal } from '@azure/msal-react';
import { LogoutOutlined } from '@mui/icons-material';

import { routePaths } from '@/core/constants/routes';

import { StyledIconLink } from '../Header.styles';

export const SignOutButton = () => {
  const { instance } = useMsal();

  const handleLogout = () => {
    instance.logoutRedirect({
      postLogoutRedirectUri: routePaths.home,
    });
  };

  return (
    <StyledIconLink onClick={handleLogout}>
      <LogoutOutlined fontSize="inherit" />
    </StyledIconLink>
  );
};
