import {
  ComponentPropsWithRef,
  ElementType,
} from 'react';

export type Nullable<T = undefined> = T | null;

export enum RequestStatus {
  IDLE = 'idle',
  LOADING = 'loading',
  SUCCEEDED = 'succeeded',
  FAILED = 'failed',
}

export type MergeElementProps<
  T extends ElementType,
  // Check this comment: https://github.com/microsoft/TypeScript/issues/21732#issuecomment-886221640
  // eslint-disable-next-line @typescript-eslint/ban-types
  P extends object = {}
> = Omit<ComponentPropsWithRef<T>, keyof P> & P;

export enum SortOrder {
  ascending = 'asc',
  descending = 'desc',
}

export type TableState = {
  page: number;
  itemsPerPage: number;
  sortBy?: string;
  sortOrder?: SortOrder;
  firstRender?: boolean;
}

export type SelectOption = {
  label: string;
  value: string | number;
}

export type MetaData = {
  totalRecordCount?: number;
  pages?: number;
  currentPage?: number;
  message?: string;
  detailMessage?: string;
  lastUpdated?: string;
  medianEpisodeCost?: number;
}

export type DefaultObject<T> = {
  [key: string]: T;
}
