import React from 'react';

import {
  ContentWrapper,
  HeaderWrapper, Item, Title,
} from '@/features/MultiLine/components/Content/ContentItem/ContentItem.styles';
import { Line } from '@/features/MultiLine/components/Content/ContentItem/Line';

type accesListProps = {
  accessName: string;
  alertsCount: number;
  claimsCount: number;
}

type Props = {
  isSelected: boolean;
  title: string;
  accessList: Array<accesListProps>;
  setLob: () => void;
  setSelectedLineOfbusiness: () => void;
}

const ContentItem = ({
  isSelected, title, accessList, setLob, setSelectedLineOfbusiness,
}: Props) => {
  const handleClick = () => {
    setSelectedLineOfbusiness();
    setLob();
  };

  return (
    <Item onClick={handleClick}>
      <HeaderWrapper>
        <Title>{title}</Title>
        {isSelected && (
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.00004 0.333008C3.31671 0.333008 0.333374 3.31634 0.333374 6.99967C0.333374 10.6797 3.31671 13.6663 7.00004 13.6663C10.68 13.6663 13.6667 10.6797 13.6667 6.99967C13.6667 3.31634 10.68 0.333008 7.00004 0.333008ZM5.66671 10.333L2.33337 6.99967L3.27671 6.05634L5.66671 8.44634L10.7234 3.38967L11.6667 4.33301L5.66671 10.333Z"
            fill="#0080E2"
          />
        </svg>
        )}
      </HeaderWrapper>

      {accessList.map(access => (
        <ContentWrapper>
          <Line
            featureName={access.accessName}
            alertsCount={access.alertsCount}
            claimsCount={access.claimsCount}
          />
        </ContentWrapper>
      ))}
    </Item>
  );
};

export { ContentItem };
