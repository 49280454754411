import {
  createReducer,
} from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

import { ConfigState } from '@/features/Config/interfaces/state';

import {
  initializeApp,
  updateLobId,
} from './actions';

export const initialState: ConfigState = {
  appHydrated: false,
  appInitialized: false,
  user: {},
};

export const configReducer = createReducer(
  initialState,
  builder => builder
    .addCase(HYDRATE, state => {
      state.appHydrated = true;
    })
    .addCase(initializeApp, (
      state,
      action
    ) => {
      state.appInitialized = true;
      state.user = action.payload as ConfigState['user'];
    })
    .addCase(updateLobId, (state, action) => {
      state.user.data!.lobId = action.payload;
    })
);

