import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '@/core/interfaces/store';

export type UIState = {
  loadersCount: number;
  lastRequestTime: number;
}

const initialState: UIState = {
  loadersCount: 0,
  lastRequestTime: 0,
};

const ui = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    addLoader(state) {
      state.loadersCount += 1;
    },
    removeLoader(state) {
      state.loadersCount -= 1;
    },
    resetTimer(state) {
      state.lastRequestTime = Date.now();
    },
  },
});

export const {
  addLoader,
  removeLoader,
  resetTimer,
} = ui.actions;

export const selectLoadersCount = (state: RootState) => state.ui.loadersCount;
export const selectLastRequestTime = (state: RootState) => state.ui.lastRequestTime;

export default ui.reducer;
