import styled from 'styled-components';

import { spacing } from '@/core/styles/mixins/spacing';

export const ContentWrapper = styled.section`
  position: absolute;
  z-index: 999;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  border-width: 1px 1px 1px 0;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.grey};
`;

export const LoadingItem = styled.section`
  width: 600px;
  height: 300px;
  border-top: 1px solid ${({ theme }) => theme.colors.grey};
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey};
  padding: ${spacing(2)};
  background-color: ${({ theme }) => theme.colors.steel20};
`;
