import {
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import { RootState } from '@/core/interfaces/store';

import { request } from '@/utils/request';

import { AppThunk } from '../store';

const { CLIENT_ID: clientId } = process.env;

type CTOverviewResponse = {
  metadata?: MetaData;
  data?: CTOverviewData;
}

type CTOverviewData = {
  graphTotal: CTOverviewGraphTotal;
  newAlerts: Array<NewAlert>;
  starredClaims: CTOverviewClaimsArray;
  topClaims: CTOverviewClaimsArray;
  lastModified: string;
}

export type CTOverviewClaimsArray = Array<CTOverviewClaim>;

export type CTOverviewClaim = {
  claimId: number;
  claimNumber: string;
  claimStatus: string;
  claimAge: string;
  bodyPart: string;
  claimantName: string;
  claimantAge: number;
  claimantAdjuster: string;
  dateReported: string;
  totalIncurred: number;
  score: number;
  star: boolean;
  tags: Array<string>;
}

export type NewAlert = {
  claimNumber: string;
  alerts: Array<any>;
  score: number;
  claimId: number;
}

export type CTOverviewGraphTotal = {
  numberClaimsOpen: number;
  numberClaimsLow: number;
  numberClaimsMed: number;
  numberClaimsHigh: number;
}

type MetaData = {
  message: string;
}

export type CTOverviewState = {
  data?: Partial<CTOverviewData>;
  metadata?: Partial<MetaData>;
  isLoading: boolean;
}

const initialState: CTOverviewState = {
  data: {},
  metadata: {},
  isLoading: false,
};

const ui = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    getCTOverviewDataRequest(state) {
      state.isLoading = true;
    },
    getCTOverviewDataSuccess(state, action: PayloadAction<CTOverviewResponse>) {
      state.isLoading = false;
      state.data = action.payload.data;
      state.metadata = action.payload.metadata;
    },
    getCTOverviewDataFailure(state) {
      state.isLoading = false;
    },
  },
});

export const {
  getCTOverviewDataRequest,
  getCTOverviewDataSuccess,
  getCTOverviewDataFailure,
} = ui.actions;

export const getCTOverviewData =
  (userEmail: string, lobId?: string | number): AppThunk<Promise<CTOverviewResponse>> => (
    dispatch
  ): Promise<CTOverviewResponse> => {
    dispatch(getCTOverviewDataRequest());

    const params = {
      clientId,
      userEmail,
      lobId,
    };

    return request
      .get<any, CTOverviewResponse>('dashboard', { params })
      .then(({ data }) => {
        dispatch(getCTOverviewDataSuccess(data as CTOverviewResponse));

        return data;
      })
      .catch(() => {
        dispatch(getCTOverviewDataFailure());
      });
  };

export const selectCTOverviewData = (state: RootState) => state.dashboard.data;
export const selectCTOverviewIsLoading = (state: RootState) => state.dashboard.isLoading;

export default ui.reducer;
