/* eslint-disable react/require-default-props */

import React from 'react';
import dayjs from 'dayjs';

import {
  StyledContainer,
  StyledLink,
} from '@/core/components/Footer/Footer.styles';

type FooterProps = {
  hasAlternateColor?: boolean;
}

export const Footer = ({
  hasAlternateColor = false,
}: FooterProps) => (
  <StyledContainer hasAlternateColor={hasAlternateColor}>
    <div>
      <StyledLink
        href="https://www.milliman.com/en/"
        target="_blank"
      >
        Milliman
      </StyledLink>
      <StyledLink
        href="https://www.milliman.com/en/terms-of-use"
        target="_blank"
      >
        Terms of Use
      </StyledLink>
      <StyledLink
        href="https://www.milliman.com/en/privacy-policy"
        target="_blank"
      >
        Privacy Policy
      </StyledLink>
      <StyledLink
        href="https://www.milliman.com/en/copyright-information"
        target="_blank"
      >
        Copyright
      </StyledLink>
    </div>
    <span>
      {`© ${dayjs().year()} Milliman, Inc. All Rights Reserved`}
    </span>
  </StyledContainer>
);
