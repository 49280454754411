import {
  startCase,
  upperFirst,
} from 'lodash';

import { SelectOption } from '@/core/interfaces/common';
import { OptionsType } from '@/core/components/Multiselect/Multiselect.interface';

export const generateSelectOptions = (
  arr: Array<string> = []): Array<SelectOption> => arr.map(item => ({
  value: item,
  label: upperFirst(item),
}));

export const generateSelectOptionForMulti = (
  arr: Array<string> = []): Array<OptionsType> => arr?.map(item => ({
  value: item,
  label: upperFirst(item),
}));

export const generateSelectOptionsFromObjectKeys = (
  obj: { [key: string]: any }): Array<OptionsType> => Object.keys(obj)?.map(item => ({
  value: startCase(item).replace(' ', '-'),
  label: startCase(item),
}));

export const prepareSelectOptions = (
  obj: { [key: string]: string } = {}
) => Object.entries(obj).map(([
  key,
  value,
]) => ({
  label: key,
  value,
}));
