const parentPaths = {
  claimsTriage: '/claims-triage',
  medBench: '/medical-benchmarking',
};

export const routePaths = {
  error: '/error',
  home: '/',
  claimsTriage: {
    overview: parentPaths.claimsTriage,
    claims: `${parentPaths.claimsTriage}/claims`,
    reports: `${parentPaths.claimsTriage}/reports`,
  },
  medBench: {
    overview: parentPaths.medBench,
    claims: `${parentPaths.medBench}/claims`,
    providers: `${parentPaths.medBench}/providers`,
    treatment: `${parentPaths.medBench}/treatment`,
    reports: `${parentPaths.medBench}/reports`,
  },
  help: '/help',
  login: '/login',
};
