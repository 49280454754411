import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '@/core/interfaces/store';
import { LineOfBusinessAccessNames } from '@/core/constants/constants';

import { request } from '@/utils/request';

import { AppThunk } from '../store';

type FAQContent = {
  message?: string;
  data?: FAQContentData;
};

export type FAQContentData = {
  [key: string]: FAQContentCategory;
};

type FAQContentCategory = {
  general: {
    [key: string]: FAQContentItem;
  };
  alertsAndNotifications: {
    [key: string]: FAQContentItem;
  };
}

export type FAQContentItem = {
  question: string;
  answer: string;
};

type Tooltips = {
  tooltipsContent?: any;
  message?: string;
  data?: TooltipsData;
};

export enum TooltipsDataEnum {
  dashboard = 'dashboard',
  claimList = 'claimList',
  claimDetailTop = 'claimDetailTop',
  claimDetailGeneral = 'claimDetailGeneral',
  claimDetailFinancial = 'claimDetailFinancial',
  claimDetailSimilar = 'claimDetailSimilar',
  claimDetailPrediction = 'claimDetailPrediction',
  claimDetailHistory = 'claimDetailHistory',
  claimComparison = 'claimComparison',
  claimTagging = 'claimTagging',
  macroLevelReporting = 'macroLevelReporting',
  mbOverview = 'mbOverview',
  mbClaims = 'mbClaims',
  mbProviders = 'mbProviders',
  mbTreatmentBenchmarks = 'mbTreatmentBenchmarks',
  mbReports = 'mbReports',
  ctOverview = 'ctOverview',
  ctClaimsListFiltering = 'ctClaimsListFiltering',
  ctReportsCycleTime = 'ctReportsCycleTime',
  ctReportClaimRisk = 'ctReportsClaimRiskAttributes',
  ctReportsModelAccuracy = 'ctReportsModelAccuracy',
  ctReportsRiskAdjustments = 'ctReportsRiskAdjustmentCost',
  claimDetailNotes = 'claimDetailNotes'
}

export type TooltipsData = {
  [TooltipsDataEnum.dashboard]: Dashboard;
  [TooltipsDataEnum.claimList]: ClaimList;
  [TooltipsDataEnum.claimDetailTop]: ClaimDetailTop;
  [TooltipsDataEnum.claimDetailGeneral]: Claim;
  [TooltipsDataEnum.claimDetailFinancial]: CTReportsFinancialInformation;
  [TooltipsDataEnum.claimDetailSimilar]: ClaimSimilarClaims;
  [TooltipsDataEnum.claimDetailPrediction]: Claim;
  [TooltipsDataEnum.claimDetailHistory]: Claim;
  [TooltipsDataEnum.claimComparison]: ClaimComparison;
  [TooltipsDataEnum.claimTagging]: Claim;
  [TooltipsDataEnum.macroLevelReporting]: MacroLevelReporting;
  [TooltipsDataEnum.mbOverview]: MBOverview;
  [TooltipsDataEnum.mbClaims]: MBClaims;
  [TooltipsDataEnum.mbProviders]: MBProviders;
  [TooltipsDataEnum.mbTreatmentBenchmarks]: MBTreatmentBenchmarks;
  [TooltipsDataEnum.mbReports]: MBReports;
  [TooltipsDataEnum.ctOverview]: CT;
  [TooltipsDataEnum.ctClaimsListFiltering]: CTFiltering;
  [TooltipsDataEnum.ctReportsCycleTime]: CTReportsCycleTime;
  [TooltipsDataEnum.ctReportClaimRisk]: CTReportsClaimRisk;
  [TooltipsDataEnum.ctReportsModelAccuracy]: CTReportsModelAccuracy;
  [TooltipsDataEnum.ctReportsRiskAdjustments]: CTReportsRiskAdjustments;
  [TooltipsDataEnum.claimDetailNotes]: CTDetailNotes;
}

export interface CTDetailNotes {
  riskCharacteristic: string;
}

export interface ClaimComparison {
  toggleOff: string;
  predictions: string;
  results: string;
  claimAge: string;
  closeLag: string;
  claimDataThru10Days: string;
  score: string;
  attorney: string;
  comorbidities: string;
  paidThru10Days: string;
  mri: string;
  surgery: string;
}

export interface Claim {
  [key: string]: string;
}

export interface CTReportsRiskAdjustments {
  riskAdjustedClaimSeverityCurve: string;
  actualCostPerClaim: string;
  riskAdjustedCostPerClaim: string;
}

export interface CTReportsCycleTime {
  timePeriod: string;
  claimAttribute: string;
  averageCloseLagByMonth: string;
  openClaimPercentageByClaim: string;
}

export interface CTReportsModelAccuracy {
  timePeriod: string;
  speedToIdentifyLargeClaims: string;
  claimSizeByScoreBand: string;
  largeClaimsSummary: string;
}

export interface CTReportsClaimRisk {
  claimRiskAttributes: string;
}

export interface CTReportsFinancialInformation {
  claimCostTrendsOverTime: string;
  timePeriodCloseDate: string;
  claimCostByCategory: string;
  timePeriodReportDate: string;
}

export interface ClaimPrediction {
  comorbidities: string;
  predictionsScore: string;
  sizeSimilarClaims: string;
  summaryScore: string;
  summaryTips: string;
  totalIncurred: string;
  totalPaid: string;
}

export interface ClaimSimilarClaims {
  similarClaims: string;
  totalIncurred: string;
  scoreAtSameAge: string;
  totalPaid: string;
  closeLag: string;
  status: string;
  surgery: string;
  attorney: string;
}

export interface ClaimDetailSimilar {
  similarClaims: string;
}

export interface ClaimDetailTop {
  score: string;
}

export interface ClaimList {
  score: string;
  claimAge: string;
}

export interface Dashboard {
  scoringClaims: string;
}

export interface MacroLevelReporting {
  riskAdjustedCost: string;
}

export interface MBClaims {
  benchmarkClaims: string;
  rating: string;
  condition: string;
  utilization: string;
  medicalCostGeneral: string;
  specialty: string;
  serviceCategory: string;
  quantityProvider: string;
  unitCost: string;
  medicalCostProvidersCost: string;
  quantityTransaction: string;
  benchmarkMedicalTransactions: string;
  surgery: string;
  medicalCostGroupHealthBenchmarks: string;
  benchmarkCost: string;
}

export interface CT {
  score: string;
  claimAge: string;
}

export interface CTFiltering {
  score: string;
  timePeriod: string;
}

export interface MBOverview {
  clientVsGroupHealthOverTime: string;
  provider: string;
  score: string;
  rating: string;
  unitCost: string;
  benchmark: string;
}

export interface MBProviders {
  ratingProviders: string;
  location: string;
  distance: string;
  specialty: string;
  medicalCost: string;
  utilization: string;
  unitCost: string;
  attorneyRep: string;
  cycleTime: string;
  ratingProvider: string;
  groupHealthBenchmark: string;
}

export interface MBReports {
  timePeriod: string;
  costDifferenceByStateClientVsGroupHealth: string;
  overTimeClientVsGroupHealth: string;
  costByBodyPart: string;
  averageMedical: string;
  percentageSurgery: string;
  medicalPerSurgicalClaim: string;
  percentageOfTotalClaims: string;
  percentageOfTotalMedical: string;
  clientVsGroupHealthMedicalCost: string;
  medicalCostVsClaimVolume: string;
  comparisionOfUnitCostAndUtilization: string;
}

export interface MBTreatmentBenchmarks {
  pharmacy: string;
  percentageClaims: string;
  utilization: string;
  unitCost: string;
  costPerClaim: string;
  utilizationType: string;
  injury: string;
}

export type AccessList = {
  // eslint-disable-next-line max-len
  accessName: LineOfBusinessAccessNames.CLAIMS_TRIAGE | LineOfBusinessAccessNames.MEDICAL_BENCHMARKING;
  alertsCount: number;
  claimsCount: number;
};

export type LinesOfBusinessData = {
  name: string;
  accessList: Array<AccessList>;
  lobId: number;
};

export type LinesOfBusinessContent = {
  message?: string;
  data?: Array<LinesOfBusinessData>;
};

export type StaticContentState = {
  faqContent: FAQContent;
  tooltips: Tooltips;
  isFaqLoading: boolean;
  isTooltipsLoading: boolean;
  lineOfBusiness: LinesOfBusinessContent;
  isLineOfBusinessLoading: boolean;
  selectedLineOfBusiness: Partial<LinesOfBusinessData> ;
};

const initialState: StaticContentState = {
  faqContent: {},
  tooltips: {},
  isFaqLoading: false,
  isTooltipsLoading: false,
  lineOfBusiness: {},
  isLineOfBusinessLoading: true,
  selectedLineOfBusiness: {},
};

const staticContent = createSlice({
  name: 'staticContent',
  initialState,
  reducers: {
    getFAQContentRequest(state) {
      state.isFaqLoading = true;
    },
    getFAQContentSuccess(state, action) {
      state.isFaqLoading = false;
      state.faqContent = action.payload;
    },
    getFAQContentFailure(state) {
      state.isFaqLoading = false;
    },
    getTooltipsRequest(state) {
      state.isTooltipsLoading = true;
    },
    getTooltipsSuccess(state, action) {
      state.isTooltipsLoading = false;
      state.tooltips = action.payload;
    },
    getTooltipsFailure(state) {
      state.isTooltipsLoading = false;
    },
    getLineOfBusinessRequest(state) {
      state.isLineOfBusinessLoading = true;
    },
    getLineOfBusinessSuccess(state, action) {
      state.isLineOfBusinessLoading = false;
      state.lineOfBusiness = action.payload;
    },
    getLineOfBusinessFailure(state) {
      state.isLineOfBusinessLoading = false;
    },
    getSelectedLineOfBusiness(state, action) {
      state.selectedLineOfBusiness = action.payload;
    },
  },
});

export const {
  getFAQContentRequest,
  getFAQContentSuccess,
  getFAQContentFailure,
  getTooltipsRequest,
  getTooltipsSuccess,
  getTooltipsFailure,
  getLineOfBusinessRequest,
  getLineOfBusinessFailure,
  getLineOfBusinessSuccess,
  getSelectedLineOfBusiness,
} = staticContent.actions;

export const getLineOfBusinessContent =
  (
    userEmail: string,
    clientId?: string | number
  ): AppThunk<Promise<unknown>> => (dispatch): Promise<unknown> => {
    dispatch(getLineOfBusinessRequest());

    return request
      .get('listLobs', {
        params: {
          user_email: userEmail,
          client_id: clientId || 1,
        },
      })
      .then(({ data }) => {
        dispatch(getLineOfBusinessSuccess(data));

        return data;
      })
      .catch(() => {
        dispatch(getLineOfBusinessFailure());
      });
  };

export const getFAQContent =
  (): AppThunk<Promise<unknown>> => (dispatch): Promise<unknown> => {
    dispatch(getFAQContentRequest());

    return request
      .get('getFAQ')
      .then(({ data }) => {
        dispatch(getFAQContentSuccess(data));

        return data;
      })
      .catch(() => {
        dispatch(getFAQContentFailure());
      });
  };

export const getTooltips =
  (): AppThunk<Promise<unknown>> => (dispatch): Promise<unknown> => {
    dispatch(getTooltipsRequest());

    return request
      .get('getTooltips')
      .then(({ data }) => {
        dispatch(getTooltipsSuccess(data));

        return data;
      })
      .catch(() => {
        dispatch(getTooltipsFailure());
      });
  };

export const selectMultipleLineOfBusiness = (
  state: RootState
) => state.staticContent.lineOfBusiness;

export const selectFaqContent = (state: RootState) => state.staticContent.faqContent;
export const selectIsFaqLoading = (state: RootState) => state.staticContent.isFaqLoading;

export const selectTooltipsContent = (state: RootState) => state.staticContent.tooltips;
export const isMultipleLineOfBusinessLoading = (state: RootState) => state
  .staticContent.isLineOfBusinessLoading;
export const selectSelectedLineOfBusiness = (state: RootState) => state
  .staticContent.selectedLineOfBusiness;
export default staticContent.reducer;
