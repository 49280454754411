import styled from 'styled-components';

import {
  fontWeight, helperText,
} from '@/core/styles/mixins/typography';

export type LabelTextProps = {
  isDisabled?: boolean;
};

export const LabelText = styled.span<LabelTextProps>`
  ${helperText}
  ${fontWeight('regular')}

  color:
    ${({
    isDisabled,
    theme,
  }) => (isDisabled ? theme.semanticColors.text.placeholder : theme.semanticColors.text.secondary)};
`;

export const CaptionText = styled(LabelText);

export const HelperText = styled(LabelText)`
  color: ${({ theme }) => theme.semanticColors.text.secondary};
`;

export const EllipsisText = styled.p.attrs(({ children }) => ({
  ...(children && typeof children === 'string' ? { title: children } : {}),
}))`
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
`;
