import {
  useEffect,
  useRef,
} from 'react';
import {
  MsalProvider,
} from '@azure/msal-react';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { ThemeProvider } from 'styled-components';
import dayjs from 'dayjs';

import { Layout } from '@/core/components/Layout';
import { wrapper } from '@/core/store/store';
import { AuthWrapper } from '@/core/components/AuthWrapper';

import { msalInstance } from '@/utils/msalUtils';

import { GlobalStyle } from '@/themes/globalStyles';
import { theme } from '@/themes/main';

import 'react-datepicker/dist/react-datepicker.css';

const BACKEND_IDLE_TIME_LIMIT = 20;

const CHECK_TIME_INTERVAL = 60 * 1000;

const MyApp = ({
  Component,
  pageProps,
}: any) => {
  const lastTimeClick = useRef(dayjs());

  const accounts = msalInstance.getAllAccounts();

  const checkIfUseActive = () => {
    const timeout = setTimeout(async () => {
      clearTimeout(timeout);

      if (dayjs(lastTimeClick.current).add(BACKEND_IDLE_TIME_LIMIT, 'minute')
        .valueOf() < dayjs().valueOf() && accounts?.[0]) {
        await msalInstance.logoutRedirect({ account: accounts[0] });
      } else {
        checkIfUseActive();
      }
    }, CHECK_TIME_INTERVAL);
  };

  useEffect(() => {
    window.addEventListener('click', () => {
      lastTimeClick.current = dayjs();
    });

    checkIfUseActive();
  }, []);

  return (
    <MsalProvider instance={msalInstance}>
      <ThemeProvider theme={theme}>
        <Head>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1"
          />
        </Head>
        <GlobalStyle />
        {Component.displayName === 'LoginPage' ||
        Component.name === 'LoginPage' ?
          (
            <Layout>
              <Component {...pageProps} />
            </Layout>
          ) :
          (
            <AuthWrapper>
              <Layout isSidebarShown>
                <Component {...pageProps} />
              </Layout>
            </AuthWrapper>
          )}

      </ThemeProvider>
    </MsalProvider>
  );
};

export default wrapper.withRedux(MyApp);
