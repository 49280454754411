import styled from 'styled-components';

import { spacing } from '@/core/styles/mixins/spacing';
import { H5 } from '@/core/components/typography';
import { fontWeight } from '@/core/styles/mixins/typography';

export const Item = styled.section`
  width: 600px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey};
  padding: ${spacing(2)};
  background-color: ${({ theme }) => theme.colors.steel20};

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.steel40};
    cursor: pointer;
  }
`;

export const Title = styled(H5)`
  ${fontWeight('bold')};
  margin-right: ${spacing(2)};
  font-size: 20px;
`;

export const HeaderWrapper = styled.section`
  display: flex;
  align-items: center;
`;

export const ContentWrapper = styled.section`
  width: 100%;
  gap: ${spacing(3)};
  padding: ${spacing(3)} ${spacing(1)} 0;
`;
