import axios, {
  AxiosError,
  AxiosInstance,
  AxiosPromise,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';
import {
  camelCase,
  snakeCase,
} from 'change-case';
import { PublicClientApplication } from '@azure/msal-browser';

import { caseConverter } from '@/utils/case-converter';

import {
  acquireAccessToken, msalInstance,
} from './msalUtils';

type AdditionalParams = {
  convertParams?: boolean;
  convertData?: boolean;
  convertResponse?: boolean;
};

export class Request {
  private instance!: AxiosInstance;

  private msalInstance: PublicClientApplication;

  constructor(msal: PublicClientApplication) {
    this.msalInstance = msal;
    this.initConfig();
  }

  initConfig() {
    this.instance = axios.create({ baseURL: '/apis/' });

    this.instance.interceptors.request.use(async (config: AxiosRequestConfig
       & AdditionalParams) => {
      const convertParams = Object.prototype.hasOwnProperty.call(config, 'convertParams') ? config.convertParams : true;
      const convertData = Object.prototype.hasOwnProperty.call(config, 'convertParams') ? config.convertData : true;

      const token = await acquireAccessToken(this.msalInstance);

      return ({
        ...config,
        headers: {
          ...config.headers,
          Authorization: `Bearer ${token}`,
        },
        data: config.data && convertData ? caseConverter(config.data, snakeCase) : config.data,
        params: config.params && convertParams ?
          caseConverter(config.params, snakeCase) :
          config.params,
      });
    });

    this.instance.interceptors.response.use(
      (response: AxiosResponse & { config: { convertResponse?: boolean } }) => {
        const convertResponse = Object.prototype.hasOwnProperty.call(response.config, 'convertResponse') ? response.config.convertResponse : true;

        return ({
          ...response,
          data: convertResponse ? caseConverter(response.data, camelCase) : response.data,

        });
      }, async error => {
        if (axios.isCancel(error)) {
          return Promise.reject(error);
        }

        if (!error.response) {
          return Promise.reject(new Error('Error do not have a response.'));
        }

        return Promise.reject(this.parseError(error));
      }
    );
  }

  parseError(error: AxiosError): AxiosError {
    const { response } = error;

    const convertedData = caseConverter(response?.data || {}, camelCase);
    let data: unknown;

    if (typeof convertedData === 'string' || typeof convertedData === 'number' || typeof convertedData === 'boolean' || typeof convertedData === 'undefined') {
      data = convertedData;
    } else {
      const nonFieldErorrs: Array<string> = response?.data?.non_field_errors || [];

      data = {
        ...convertedData,
        _error: nonFieldErorrs,
      };
    }

    return {
      ...error,
      response: {
        ...response,
        data,
      } as AxiosResponse,
    };
  }

  get<P, QP = unknown>(
    url: string,
    config?: AxiosRequestConfig & { params?: QP } & AdditionalParams
  ): AxiosPromise<P> {
    return this.instance.get(url, config);
  }

  post<P>(url: string, data?: unknown, config?: AxiosRequestConfig): AxiosPromise<P> {
    return this.instance.post(url, data, config);
  }

  options<P>(url: string, config?: AxiosRequestConfig): AxiosPromise<P> {
    return this.instance.options(url, config);
  }

  patch<P>(url: string, data?: unknown, config?: AxiosRequestConfig): AxiosPromise<P> {
    return this.instance.patch(url, data, config);
  }

  put<P>(url: string, data?: unknown, config?: AxiosRequestConfig): AxiosPromise<P> {
    return this.instance.put(url, data, config);
  }

  delete<P>(url: string, config?: AxiosRequestConfig): AxiosPromise<P> {
    return this.instance.delete(url, config);
  }
}

export const request = new Request(msalInstance);

