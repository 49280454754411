import styled from 'styled-components';

import { helperText } from '@/core/styles/mixins/typography';
import { spacing } from '@/core/styles/mixins/spacing';

export const StyledOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 172px;
  height: 62px;
  background-color: ${({ theme }) => theme.semanticColors.ui.background};
  color: ${({ theme }) => theme.semanticColors.active.primary};
`;

export const StyledText = styled.p`
  ${helperText}

  margin: ${spacing(0.5)} 0;
  color: ${({ theme }) => theme.semanticColors.text.secondary};
`;
