import { css } from 'styled-components';

import type { Theme } from '@/themes/themes';

export const fontFamily = (name: keyof Theme['typography']['fonts']) => css`
  font-family: ${({ theme }) => theme.typography.fonts[name]};
`;

export const fontWeight = (weight: keyof Theme['typography']['fontWeights']) => css`
  font-weight: ${({ theme }) => theme.typography.fontWeights[weight]};
`;

export const fontSize = (size: keyof Theme['typography']['fontSizes']) => css`
  font-size: ${({ theme }) => theme.typography.fontSizes[size]}px;
`;

export const helperText = css`
  ${fontSize('smaller')}

  line-height: 16px;
  letter-spacing: 0.32px;
`;

export const bodyText = css`
  ${fontFamily('body')}
  ${fontSize('base')}

  line-height: 18px;
  letter-spacing: 0.16px;
`;
