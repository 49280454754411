export const typography = {
  fonts: {
    code: "'JetBrains Mono', monospace",
    body: "'Open Sans', sans-serif",
  },
  fontSizes: {
    smaller: 12,
    small: 13,
    base: 14,
    bigger: 16,
    large: 18,
    larger: 20,
  },
  fontWeights: {
    light: 300,
    regular: 400,
    semiBold: 600,
    bold: 700,
  },
  letterSpacing: {
    small: 0.16,
    regular: 0.32,
  },
} as const;
