import {
  Action,
  combineReducers,
  Reducer,
} from 'redux';
import reduceReducers from 'reduce-reducers';

import medicalBenchmarking from '@/core/store/reducers/medicalBenchmarking';
import notes from '@/core/store/reducers/notesList';
import { hydrateReducer } from '@/core/store/reducers/hydrate';
import claimDetailsReducer from '@/core/store/reducers/claimDetails';
import uiReducer from '@/core/store/reducers/ui';
import dasboardReducer from '@/core/store/reducers/ctOverview';
import staticContentReducer from '@/core/store/reducers/staticContent';
import reportsReducer from '@/core/store/reducers/reports';
import { RootState } from '@/core/interfaces/store';

import claimsReducer from '@/features/CT/ClaimList/store/slice/claims';
import { configReducer } from '@/features/Config/store';

const featuresReducer = combineReducers({
  config: configReducer,
  claims: claimsReducer,
  claimDetails: claimDetailsReducer,
  dashboard: dasboardReducer,
  ui: uiReducer,
  staticContent: staticContentReducer,
  reports: reportsReducer,
  medicalBenchmarking,
  notes,
});

export const rootReducer = reduceReducers<RootState>(
  hydrateReducer,
  featuresReducer
) as Reducer<RootState, Action>;

