export const colors = {
  // black
  black: '#000000',
  // greys
  white: '#FFFFFF',
  grey: '#c6c9ca',
  lightGray: '#DEDEDE',
  charcoal: '#39414D',
  charcoal20: '#D7D9DB',
  charcoal60: '#888D94',
  charcoal60op: 'rgba(57, 65, 77, 0.5);',
  charcoal80: '#616771',
  charcoalDark: '#22272E',
  steel: '#B4B4B4',
  steel10: '#F9FAFA',
  steel20: '#F8F8F8',
  steel40: '#E8E9EA',
  steel60: '#E8E9EA',
  slate: '#727A7D',
  slate20: '#E3E4E5',
  slate40: '#E8E9EA',
  slate60: '#AAAFB1',
  barChartGray: '#8C8C8C',
  darkGray: '#838a8d',
  // pinks
  lightPink: '#FFDBDC',
  // reds
  darkRed: '#9f1b3a',
  cranberry: '#A52040',
  cranberry10: '#F7E5E9',
  cranberry20: '#EDD2D9',
  cranberry60Dark: '#631326',
  cranberry80Dark: '#841A33',
  // greens
  emerald: '#00A663',
  emerald10: '#E6F6EF',
  pine10: '#E6F0EC',
  pine20: '#E6F1EC',
  spring: '#74C061',
  // yellows
  yellow: '#FFC800',
  darkYellow: '#FFC800',
  lightYellow: '#FFFAE6',
  gold: '#FFA200',
  gold20: '#FFECCC',
  // blues
  vibrantBlue: '#0080E2',
  vibrantBlue20: '#CCE6F9',
  vibrantBlue40: '#99CCF3',
  vibrantBlue60: '#339AE8',
  vibrantBlue80Dark: '#0067B5',
  skyBlue: '#50BEE1',
  skyBlue10: '#EEF8FC',
  millimanBlue: '#004877',
  milimanBlue20: '#004D87',
  navbarDarkBlue: '#001a2d',
  darkBlue: '#005A9E',
  // orange
  orange: '#FF832B',
} as const;

export const customMultiselectColors = {
  veryDarkGray: '#161616',
  darkGray: '#919191',
  lighterDarkGray: '#393939',
  hoverGray: '#CBCBCB',
  hoverGray2: '#474747',
  lightGray: '#F1F1F1',
  veryLightGray: '#E0E0E0',
  text01: '#39414D',
  text02: '#616771',
  text03: '#AAAFB1',
  field01: '#F8F8F8',
  ui03: '#E8E9EA',
  disabled02: '#B4B4B4',
  disabled01: '#E3E4E5',
  hoverui: '#E8E9EA',
} as const;

export const semanticColors = {
  ui: {
    background: colors.steel10,
    1: colors.steel20,
    2: colors.white,
    3: colors.slate40,
    4: colors.slate,
    5: colors.charcoal,
    6: colors.charcoalDark,
    7: colors.slate60,
  },
  interactive: {
    primary: colors.vibrantBlue,
    secondary: colors.charcoal,
    tertiary: colors.white,
    disabled: colors.slate20,
  },
  text: {
    primary: colors.charcoal,
    secondary: colors.charcoal80,
    tertiary: colors.charcoal,
    tertiaryLight: colors.vibrantBlue,
    placeholder: colors.slate60,
    interactive: colors.white,
    inverse: {
      primary: colors.white,
      secondary: colors.charcoal,
    },
  },
  links: {
    primary: colors.vibrantBlue,
    inverse: {
      primary: colors.vibrantBlue40,
    },
    visited: colors.skyBlue,
    highContrast: colors.vibrantBlue80Dark,
  },
  icons: {
    primary: colors.charcoal,
    secondary: colors.slate,
    interactive: colors.white,
  },
  support: {
    error: colors.cranberry,
    danger: colors.cranberry,
    danger20: colors.cranberry20,
    success: colors.emerald,
    warning: colors.yellow,
    warning20: colors.gold20,
    info: colors.skyBlue,
    tint: colors.skyBlue10,
    tint01: colors.cranberry10,
    tint02: colors.emerald10,
    inverse: {
      error: colors.cranberry,
      success: colors.spring,
      warning: colors.gold,
      info: colors.vibrantBlue,
    },
  },
  overlay: colors.charcoal60op,
  focus: colors.vibrantBlue,
  hover: {
    primary: colors.vibrantBlue60,
    primaryText: colors.vibrantBlue80Dark,
    secondary: colors.charcoal60,
    tertiary: colors.vibrantBlue,
    ui: colors.steel40,
    row: colors.steel40,
    danger: colors.cranberry80Dark,
  },
  active: {
    primary: colors.vibrantBlue,
    secondary: colors.charcoal80,
    tertiary: colors.vibrantBlue80Dark,
    ui: colors.steel40,
    danger: colors.cranberry60Dark,
  },
  selected: colors.steel20,
  highlight: colors.vibrantBlue20,
  disabled: {
    1: colors.steel20,
    2: colors.steel,
    3: colors.slate,
  },
} as const;
