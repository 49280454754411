import { PublicClientApplication } from '@azure/msal-browser';
import {
  backendConfig,
  msalConfig,
} from 'authConfig';

// This should be the same instance you pass to MsalProvider
export const msalInstance = new PublicClientApplication(msalConfig);

export const acquireAccessToken = async (instance: PublicClientApplication) => {
  const activeAccount = instance.getActiveAccount();
  const accounts = instance.getAllAccounts();

  const request = {
    ...backendConfig,
    account: activeAccount || accounts[0],
  };

  const response = await instance.acquireTokenSilent(request)
    .catch(() => msalInstance.acquireTokenRedirect(request));

  return response?.accessToken;
};
