import React from 'react';
import {
  useDispatch, useSelector,
} from 'react-redux';

import {
  getSelectedLineOfBusiness,
  LinesOfBusinessData,
  selectMultipleLineOfBusiness,
} from '@/core/store/reducers/staticContent';
import { Loader } from '@/core/components/Loader';

import {
  ContentWrapper,
  LoadingItem,
} from '@/features/MultiLine/components/Content/Content.styles';
import { ContentItem } from '@/features/MultiLine/components/Content/ContentItem';
import { updateLobId } from '@/features/Config/store/actions';
import { selectLobId } from '@/features/Config/store/selectors';

const Content = () => {
  const lobId = useSelector(selectLobId);
  const { data } = useSelector(selectMultipleLineOfBusiness);
  const dispatch = useDispatch();

  const setLob = (id: number) => dispatch(updateLobId(id));
  const setSelectedLineOfbusiness =
   (lob: LinesOfBusinessData) => dispatch(getSelectedLineOfBusiness(lob));

  if (!data) {
    return (
      <ContentWrapper>
        <LoadingItem>
          <Loader isAlwaysVisible />
        </LoadingItem>
      </ContentWrapper>
    );
  }

  return (
    <ContentWrapper>
      {
        data?.map(section => (
          <ContentItem
            setSelectedLineOfbusiness={() => setSelectedLineOfbusiness(section)}
            setLob={() => setLob(section.lobId)}
            isSelected={Number(lobId) === Number(section.lobId)}
            title={section.name}
            accessList={section.accessList}
          />

        ))
      }

    </ContentWrapper>
  );
};

export { Content };
