import axios, {
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';
import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '@/core/interfaces/store';
import {
  DefaultObject,
  Nullable,
} from '@/core/interfaces/common';

import { request } from '@/utils/request';
import { prepareSelectOptions } from '@/utils/generateSelectOptions';

import { AppThunk } from '../store';

const { CLIENT_ID: clientId } = process.env;

type DataResponse<T> = {
  metadata?: MetaData;
  data?: T;
}

export interface ModelAccuracyLargeClaims {
  metadata?: MetaDataPaginated;
  data?: Array<ModelAccuracyLargeClaimsDataRow>;
}

export interface ModelAccuracyLargeClaimsDataRow {
  claimId: number;
  claimNumber: string;
  dateReported: string;
  natureInjury: string;
  bodyPart: string;
  scoreDay: number;
  scoreUlt: number;
  totalPaid: number;
  totalInc: number;
  claimStatus: string;
}

type RiskAdjustedCost = DataResponse<RiskAdjustedCostData>;

type RiskAdjustedCostData = {
  table: RiskAdjustedCostTable;
}

type RiskAdjustedCostTable = {
  [key: string]: RiskAdjustedCostTableItem;
}

type RiskAdjustedCostTableItem = {
  year: string | number;
  claimCount: number;
  actualCost: number;
  raCost: number;
  differenceClaim: number;
  differenceTotal: number;
  differencePerc: number;
}

type CycleTimeAverage = DataResponse<CycleTimeAverageData>;

type CycleTimeAverageData = {
  graphs: CycleTimeAverageGraphs;
}

export type CycleTimeAverageGraphs = {
  avg: CycleTimeAverageGraphData;
  rollingAvg: CycleTimeAverageGraphData;
}

type CycleTimeAverageGraphData = {
  [key: string]: CycleTimeAverageGraphDataItem;
}

export type CycleTimeAverageGraphDataItem = {
  epoch: string;
  value: number;
  yoy: number;
}

type RiskAdjustedSeverityCurve = DataResponse<RiskAdjustedSeverityCurveData>;

type RiskAdjustedSeverityCurveData = {
  table: RiskAdjustedSeverityCurveDataTable;
}

export type RiskAdjustedSeverityCurveDataTable = {
  actual: RiskAdjustedSeverityCurveDataSeries;
  expected: RiskAdjustedSeverityCurveDataSeries;
}

type RiskAdjustedSeverityCurveDataSeries = {
  [key: string]: RiskAdjustedSeverityCurveDataSeriesItem;
}

export type RiskAdjustedSeverityCurveDataSeriesItem = {
  months: number;
  value: number;
}

type ClaimSizeByScoreBand = DataResponse<ClaimSizeByScoreBandData>;

export type ClaimSizeByScoreBandData = {
  table: Array<ClaimSizeByScoreBandDataRow>;
}

export type ClaimSizeByScoreBandDataRow = {
  scoreBand: string;
  scoreMin?: number;
  scoreMax?: number;
  claimCount: number;
  claimCountPerc: number;
  claimTotalPaid: number;
  claimAvgPaid: number;
  claimsLte_25k: number;
  claims_25k_50k: number;
  claims_50k_75k: number;
  claims_75k_100k: number;
  claimsGte_100k: number;
}

type LargeClaimsIdentificationSpeed = DataResponse<LargeClaimsIdentificationSpeedData>;

type LargeClaimsIdentificationSpeedData = {
  graphs: LargeClaimsIdentificationSpeedGraphs;
}

export type LargeClaimsIdentificationSpeedGraphs = {
  numberOfClaims: LargeClaimsIdentificationSpeedGraphData;
  cumulativePercentage: LargeClaimsIdentificationSpeedGraphData;
}
type LargeClaimsIdentificationSpeedGraphData = {
  [key: string]: LargeClaimsIdentificationSpeedGraphDataItem;
}

export type LargeClaimsIdentificationSpeedGraphDataItem = {
  days: string;
  value: number;
}

type CycleCloseLagByCloseDate = DataResponse<CycleCloseLagByCloseDateData>;

type CycleCloseLagByCloseDateData = {
  graphs: CycleCloseLagByCloseDateGraphs;
}

export type CycleCloseLagByCloseDateGraphs = {
  [key: string]: CycleCloseLagByCloseDateGraphData;
}

type CycleCloseLagByCloseDateGraphData = {
  [key: string]: CycleCloseLagByCloseDateGraphDataItem;
}

export type CycleCloseLagByCloseDateGraphDataItem = {
  months: number;
  value: number;
  yoy: number;
}

type ReportCycleLagOptions = DataResponse<ReportCycleLagOptionsData>;

export type ReportCycleLagOptionsData = {
  claimAttribute: DefaultObject<string>;
};

type ClaimRiskAttributes = DataResponse<ClaimRiskAttributesData>;

type ClaimRiskAttributesOptions = DataResponse<ClaimRiskAttributesOptionsData>;

type ClaimRiskAttributesData = {
  graphs: ClaimRiskAttributesGraphs;
}

export type ClaimRiskAttributesOptionsData = {
  claimAttribute: DefaultObject<string>;
  calculatedBy: DefaultObject<string>;
};

export type ClaimRiskAttributesGraphs = {
  [key: string]: ClaimRiskAttributesGraphData;
}

export type ClaimRiskAttributesGraphData = {
  [key: string]: ClaimRiskAttributesGraphDataItem;
}

export type ClaimRiskAttributesGraphDataItem = {
  epoch: string;
  value: number;
  yoy: number;
}

type FinancialClaimCostByCategory = DataResponse<FinancialClaimCostByCategoryData>;

type FinancialClaimCostByCategoryData = {
  graphs: ClaimCostByCategoryGraphs;
}

type FinancialClaimCostByCategoryOptions = DataResponse<FinancialClaimCostByCategoryOptionsData>;

export type FinancialClaimCostByCategoryOptionsData = {
  value: DefaultObject<string>;
  category: DefaultObject<string>;
  calculatedBy: DefaultObject<string>;
  claimStatus: DefaultObject<string>;
};

export type ClaimCostByCategoryGraphs = {
  [key: string]: ClaimCostByCategoryGraphsDataItem;
}

export type ClaimCostByCategoryGraphsDataItem = {
  category: string;
  value: number;
}

type FinancialClaimCostTrend = DataResponse<FinancialClaimCostTrendData>;

type FinancialClaimCostTrendOptions = DataResponse<FinancialClaimCostTrendOptionsData>;

export type FinancialClaimCostTrendData = {
  graphs: ClaimCostTrendGraphs;
}

export type FinancialClaimCostTrendOptionsData = {
  value: DefaultObject<string>;
  category: DefaultObject<string>;
  calculatedBy: DefaultObject<string>;
};

export type ClaimCostTrendGraphs = {
  [key: string]: ClaimCostTrendGraphData;
  All: ClaimCostTrendGraphData;
}

export type ClaimCostTrendGraphData = {
  [key: string]: ClaimCostTrendGraphDataItem;
}

export type ClaimCostTrendGraphDataItem = {
  epoch: string;
  value: number;
  yoy: number;
}

interface MetaData {
  message: string;
  lastUpdated?: string;
}

interface MetaDataPaginated extends MetaData {
  pages?: number;
  totalRecordCount?: number;
}

export type ClaimSizefiltersApplied = {
  claimStatus?: string;
  claimAge?: number;
  timePeriod?: number;
}

export type LargeClaimsFiltersApplied = {
  scoreDay?: number;
  timePeriod?: number;
}

export type ReportsState = {
  financialClaimCostTrend: FinancialClaimCostTrend;
  financialClaimCostTrendOptions: FinancialClaimCostTrendOptions;
  financialClaimCostCategory: FinancialClaimCostByCategory;
  financialClaimCostCategoryOptions: FinancialClaimCostByCategoryOptions;
  claimRiskAttributes: ClaimRiskAttributes;
  claimRiskAttributesOptions: ClaimRiskAttributesOptions;
  largeClaimsIdentificationSpeed: LargeClaimsIdentificationSpeed;
  claimSizeByScoreBand: ClaimSizeByScoreBand;
  claimSizeFiltersApplied: ClaimSizefiltersApplied;
  cycleCloseLagByCloseDate: CycleCloseLagByCloseDate;
  reportCycleLagOptions: ReportCycleLagOptions;
  modelAccuracyLargeClaims: ModelAccuracyLargeClaims;
  largeClaimsFiltersApplied: LargeClaimsFiltersApplied;
  cycleTimeAverage: CycleTimeAverage;
  riskAdjustedSeverityCurve: RiskAdjustedSeverityCurve;
  riskAdjustedCost: RiskAdjustedCost;
  isFinancialClaimCostTrendLoading: boolean;
  isFinancialClaimCostTrendOptionsLoading: boolean;
  isFinancialClaimCostCategoryLoading: boolean;
  isFinancialClaimCostCategoryOptionsLoading: boolean;
  isCycleCloseLagByCloseDateLoading: boolean;
  isReportCycleLagOptionsLoading: boolean;
  isCycleTimeAverageLoading: boolean;
  isClaimRiskAttributesLoading: boolean;
  isClaimRiskAttributesOptionsLoading: boolean;
  isLargeClaimsIdentificationSpeedLoading: boolean;
  isClaimSizeByScoreBandLoading: boolean;
  isModelAccuracyLargeClaimsLoading: boolean;
  isRiskAdjustedSeverityCurveLoading: boolean;
  isRiskAdjustedCostLoading: boolean;
  claimSizeByScoreBandError: Nullable<AxiosResponse['data']>;
  modelAccuracyLargeClaimsError: Nullable<AxiosResponse['data']>;
}

const initialState: ReportsState = {
  financialClaimCostTrend: {},
  isFinancialClaimCostTrendLoading: false,
  financialClaimCostTrendOptions: {},
  isFinancialClaimCostTrendOptionsLoading: false,
  financialClaimCostCategory: {},
  isFinancialClaimCostCategoryLoading: false,
  financialClaimCostCategoryOptions: {},
  isFinancialClaimCostCategoryOptionsLoading: false,
  cycleCloseLagByCloseDate: {},
  isCycleCloseLagByCloseDateLoading: false,
  reportCycleLagOptions: {},
  isReportCycleLagOptionsLoading: false,
  cycleTimeAverage: {},
  isCycleTimeAverageLoading: false,
  claimRiskAttributes: {},
  isClaimRiskAttributesLoading: false,
  claimRiskAttributesOptions: {},
  isClaimRiskAttributesOptionsLoading: false,
  largeClaimsIdentificationSpeed: {},
  isLargeClaimsIdentificationSpeedLoading: false,
  claimSizeByScoreBand: {},
  claimSizeByScoreBandError: null,
  claimSizeFiltersApplied: { timePeriod: 0 },
  isClaimSizeByScoreBandLoading: false,
  modelAccuracyLargeClaims: {},
  modelAccuracyLargeClaimsError: null,
  isModelAccuracyLargeClaimsLoading: false,
  largeClaimsFiltersApplied: {
    timePeriod: 5,
    scoreDay: 30,
  },
  riskAdjustedSeverityCurve: {},
  isRiskAdjustedSeverityCurveLoading: false,
  riskAdjustedCost: {},
  isRiskAdjustedCostLoading: false,
};

const reports = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    clearFinancialClaimCostTrend(state) {
      state.financialClaimCostTrend = {};
    },
    getFinancialClaimCostTrendRequest(state) {
      state.isFinancialClaimCostTrendLoading = true;
    },
    getFinancialClaimCostTrendSuccess(state, action) {
      state.isFinancialClaimCostTrendLoading = false;
      state.financialClaimCostTrend = action.payload;
    },
    getFinancialClaimCostTrendFailure(state) {
      state.isFinancialClaimCostTrendLoading = false;
    },
    getFinancialClaimCostTrendOptionsRequest(state) {
      state.isFinancialClaimCostTrendOptionsLoading = true;
    },
    getFinancialClaimCostTrendOptionsSuccess(state, action) {
      state.isFinancialClaimCostTrendOptionsLoading = false;
      state.financialClaimCostTrendOptions = action.payload;
    },
    getFinancialClaimCostTrendOptionsFailure(state) {
      state.isFinancialClaimCostTrendOptionsLoading = false;
    },
    clearFinancialClaimCostCategory(state) {
      state.financialClaimCostCategory = {};
    },
    getFinancialClaimCostCategoryRequest(state) {
      state.isFinancialClaimCostCategoryLoading = true;
    },
    getFinancialClaimCostCategorySuccess(state, action) {
      state.isFinancialClaimCostCategoryLoading = false;
      state.financialClaimCostCategory = action.payload;
    },
    getFinancialClaimCostCategoryFailure(state) {
      state.isFinancialClaimCostCategoryLoading = false;
    },
    getFinancialClaimCostCategoryOptionsRequest(state) {
      state.isFinancialClaimCostCategoryOptionsLoading = true;
    },
    getFinancialClaimCostCategoryOptionsSuccess(state, action) {
      state.isFinancialClaimCostCategoryOptionsLoading = false;
      state.financialClaimCostCategoryOptions = action.payload;
    },
    getFinancialClaimCostCategoryOptionsFailure(state) {
      state.isFinancialClaimCostCategoryOptionsLoading = false;
    },
    getClaimRiskAttributesRequest(state) {
      state.isClaimRiskAttributesLoading = true;
    },
    getClaimRiskAttributesSuccess(state, action) {
      state.isClaimRiskAttributesLoading = false;
      state.claimRiskAttributes = action.payload;
    },
    getClaimRiskAttributesFailure(state) {
      state.isClaimRiskAttributesLoading = false;
    },
    clearClaimRiskAttributes(state) {
      state.claimRiskAttributes = {};
    },
    getClaimRiskAttributesOptionsRequest(state) {
      state.isClaimRiskAttributesOptionsLoading = true;
    },
    getClaimRiskAttributesOptionsSuccess(state, action) {
      state.isClaimRiskAttributesOptionsLoading = false;
      state.claimRiskAttributesOptions = action.payload;
    },
    getClaimRiskAttributesOptionsFailure(state) {
      state.isClaimRiskAttributesOptionsLoading = false;
    },
    getModelAccuracyIdentifyLargeClaimsSpeedRequest(state) {
      state.isLargeClaimsIdentificationSpeedLoading = true;
    },
    getModelAccuracyIdentifyLargeClaimsSpeedSuccess(state, action) {
      state.isLargeClaimsIdentificationSpeedLoading = false;
      state.largeClaimsIdentificationSpeed = action.payload;
    },
    getModelAccuracyIdentifyLargeClaimsSpeedFailure(state) {
      state.isLargeClaimsIdentificationSpeedLoading = false;
    },
    clearModelAccuracyIdentifyLargeClaimsSpeed(state) {
      state.largeClaimsIdentificationSpeed = {};
    },
    getModelAccuracyClaimSizeByScoreBandRequest(state) {
      state.isClaimSizeByScoreBandLoading = true;
    },
    getModelAccuracyClaimSizeByScoreBandSuccess(state, action) {
      state.isClaimSizeByScoreBandLoading = false;
      state.claimSizeByScoreBand = action.payload;
    },
    getModelAccuracyClaimSizeByScoreBandFailure(state, action) {
      state.isClaimSizeByScoreBandLoading = false;
      state.claimSizeByScoreBandError = action.payload;
      state.claimSizeByScoreBand = {};
    },
    getModelAccuracyClaimSizeByScoreBandCancelled(state) {
      state.claimSizeByScoreBand = {
        metadata: {
          message: 'request cancelled',
        },
      };
    },
    clearModelAccuracyClaimSizeByScoreBand(state) {
      state.claimSizeByScoreBand = {};
    },
    setModelAccuracyClaimSizeFiltersApplied(state, action) {
      state.claimSizeFiltersApplied = action.payload;
    },
    setModelAccuracyLargeClaimsFiltersApplied(state, action) {
      state.largeClaimsFiltersApplied = action.payload;
    },
    clearReportCycleCloseLagByCloseDate(state) {
      state.cycleCloseLagByCloseDate = {};
    },
    getReportCycleCloseLagByCloseDateRequest(state) {
      state.isCycleCloseLagByCloseDateLoading = true;
    },
    getReportCycleCloseLagByCloseDateSuccess(state, action) {
      state.isCycleCloseLagByCloseDateLoading = false;
      state.cycleCloseLagByCloseDate = action.payload;
    },
    getReportCycleCloseLagByCloseDateFailure(state) {
      state.isCycleCloseLagByCloseDateLoading = false;
    },
    getReportCycleLagOptionsRequest(state) {
      state.isReportCycleLagOptionsLoading = true;
    },
    getReportCycleLagOptionsSuccess(state, action) {
      state.isReportCycleLagOptionsLoading = false;
      state.reportCycleLagOptions = action.payload;
    },
    getReportCycleLagOptionsFailure(state) {
      state.isReportCycleLagOptionsLoading = false;
    },
    clearModelAccuracyLargeClaims(state) {
      state.modelAccuracyLargeClaims = {};
    },
    getModelAccuracyLargeClaimsRequest(state) {
      state.isModelAccuracyLargeClaimsLoading = true;
    },
    getModelAccuracyLargeClaimsSuccess(state, action) {
      state.isModelAccuracyLargeClaimsLoading = false;
      state.modelAccuracyLargeClaims = action.payload;
    },
    getModelAccuracyLargeClaimsFailure(state, action) {
      state.isModelAccuracyLargeClaimsLoading = false;
      state.modelAccuracyLargeClaimsError = action.payload;
      state.modelAccuracyLargeClaims = {};
    },
    getModelAccuracyLargeClaimsCancelled(state) {
      state.modelAccuracyLargeClaims = {
        metadata: {
          message: 'request cancelled',
        },
      };
    },
    clearReportCycleAverage(state) {
      state.cycleTimeAverage = {};
    },
    getReportCycleAverageRequest(state) {
      state.isCycleTimeAverageLoading = true;
    },
    getReportCycleAverageSuccess(state, action) {
      state.isCycleTimeAverageLoading = false;
      state.cycleTimeAverage = action.payload;
    },
    getReportCycleAverageFailure(state) {
      state.isCycleTimeAverageLoading = false;
    },
    clearRiskAdjustedSeverityCurve(state) {
      state.riskAdjustedSeverityCurve = {};
    },
    getRiskAdjustedSeverityCurveRequest(state) {
      state.isRiskAdjustedSeverityCurveLoading = true;
    },
    getRiskAdjustedSeverityCurveSuccess(state, action) {
      state.isRiskAdjustedSeverityCurveLoading = false;
      state.riskAdjustedSeverityCurve = action.payload;
    },
    getRiskAdjustedSeverityCurveFailure(state) {
      state.isRiskAdjustedSeverityCurveLoading = false;
    },
    clearRiskAdjustedCost(state) {
      state.riskAdjustedCost = {};
    },
    getRiskAdjustedCostRequest(state) {
      state.isRiskAdjustedCostLoading = true;
    },
    getRiskAdjustedCostSuccess(state, action) {
      state.isRiskAdjustedCostLoading = false;
      state.riskAdjustedCost = action.payload;
    },
    getRiskAdjustedCostFailure(state) {
      state.isRiskAdjustedCostLoading = false;
    },
  },
});

export const {
  clearFinancialClaimCostTrend,
  getFinancialClaimCostTrendRequest,
  getFinancialClaimCostTrendSuccess,
  getFinancialClaimCostTrendFailure,
  getFinancialClaimCostTrendOptionsRequest,
  getFinancialClaimCostTrendOptionsSuccess,
  getFinancialClaimCostTrendOptionsFailure,
  clearFinancialClaimCostCategory,
  getFinancialClaimCostCategoryRequest,
  getFinancialClaimCostCategorySuccess,
  getFinancialClaimCostCategoryFailure,
  getFinancialClaimCostCategoryOptionsRequest,
  getFinancialClaimCostCategoryOptionsSuccess,
  getFinancialClaimCostCategoryOptionsFailure,
  clearReportCycleCloseLagByCloseDate,
  getReportCycleCloseLagByCloseDateRequest,
  getReportCycleCloseLagByCloseDateSuccess,
  getReportCycleCloseLagByCloseDateFailure,
  getReportCycleLagOptionsRequest,
  getReportCycleLagOptionsSuccess,
  getReportCycleLagOptionsFailure,
  clearReportCycleAverage,
  getReportCycleAverageRequest,
  getReportCycleAverageSuccess,
  getReportCycleAverageFailure,
  getClaimRiskAttributesRequest,
  getClaimRiskAttributesSuccess,
  getClaimRiskAttributesFailure,
  clearClaimRiskAttributes,
  getClaimRiskAttributesOptionsRequest,
  getClaimRiskAttributesOptionsSuccess,
  getClaimRiskAttributesOptionsFailure,
  getModelAccuracyIdentifyLargeClaimsSpeedRequest,
  getModelAccuracyIdentifyLargeClaimsSpeedSuccess,
  getModelAccuracyIdentifyLargeClaimsSpeedFailure,
  clearModelAccuracyIdentifyLargeClaimsSpeed,
  getModelAccuracyClaimSizeByScoreBandRequest,
  getModelAccuracyClaimSizeByScoreBandSuccess,
  getModelAccuracyClaimSizeByScoreBandFailure,
  getModelAccuracyClaimSizeByScoreBandCancelled,
  clearModelAccuracyClaimSizeByScoreBand,
  getRiskAdjustedSeverityCurveRequest,
  getRiskAdjustedSeverityCurveSuccess,
  getRiskAdjustedSeverityCurveFailure,
  clearRiskAdjustedSeverityCurve,
  getRiskAdjustedCostRequest,
  getRiskAdjustedCostSuccess,
  getRiskAdjustedCostFailure,
  clearRiskAdjustedCost,
  setModelAccuracyClaimSizeFiltersApplied,
  clearModelAccuracyLargeClaims,
  getModelAccuracyLargeClaimsRequest,
  getModelAccuracyLargeClaimsSuccess,
  getModelAccuracyLargeClaimsFailure,
  getModelAccuracyLargeClaimsCancelled,
  setModelAccuracyLargeClaimsFiltersApplied,
} = reports.actions;

export const getFinancialClaimCostTrend =
  (lobId?: string | number, props = {}): AppThunk<Promise<unknown>> => dispatch => {
    dispatch(getFinancialClaimCostTrendRequest());

    const params = {
      lobId,
      clientId,
      ...props,
    };

    return request
      .get('getReportFinancialClaimCostTrend', { params })
      .then(({ data }) => {
        dispatch(getFinancialClaimCostTrendSuccess(data));

        return data;
      })
      .catch(() => {
        dispatch(getFinancialClaimCostTrendFailure());
      });
  };

export const getFinancialClaimCostTrendOptions =
  (lobId?: string | number, props = {}): AppThunk<Promise<unknown>> => dispatch => {
    dispatch(getFinancialClaimCostTrendOptionsRequest());

    const params = {
      lobId,
      clientId,
      ...props,
    };

    return request
      .get('getReportFinancialClaimCostTrendOptions', {
        params,
        convertResponse: false,
      })
      .then(({ data }) => {
        dispatch(getFinancialClaimCostTrendOptionsSuccess(data));

        return data;
      })
      .catch(() => {
        dispatch(getFinancialClaimCostTrendOptionsFailure());
      });
  };

export const getFinancialClaimCostCategoryOptions =
  (lobId?: string | number, props = {}): AppThunk<Promise<unknown>> => dispatch => {
    dispatch(getFinancialClaimCostCategoryOptionsRequest());

    const params = {
      lobId,
      clientId,
      ...props,
    };

    return request
      .get('getReportFinancialClaimCostCategoryOptions', {
        params,
        convertResponse: false,
      })
      .then(({ data }) => {
        dispatch(getFinancialClaimCostCategoryOptionsSuccess(data));

        return data;
      })
      .catch(() => {
        dispatch(getFinancialClaimCostCategoryOptionsFailure());
      });
  };

export const getFinancialClaimCostCategory =
  (lobId?: string | number, props = {}): AppThunk<Promise<unknown>> => dispatch => {
    dispatch(getFinancialClaimCostCategoryRequest());

    const params = {
      lobId,
      clientId,
      ...props,
    };

    return request
      .get('getReportFinancialClaimCostCategory', { params })
      .then(({ data }) => {
        dispatch(getFinancialClaimCostCategorySuccess(data));

        return data;
      })
      .catch(() => {
        dispatch(getFinancialClaimCostCategoryFailure());
      });
  };

export const getReportCycleCloseLagByCloseDate =
  (lobId?: string | number, props = {}): AppThunk<Promise<unknown>> => dispatch => {
    dispatch(getReportCycleCloseLagByCloseDateRequest());

    const params = {
      lobId,
      clientId,
      ...props,
    };

    return request
      .get('getReportCycleLag', { params })
      .then(({ data }) => {
        dispatch(getReportCycleCloseLagByCloseDateSuccess(data));

        return data;
      })
      .catch(() => {
        dispatch(getReportCycleCloseLagByCloseDateFailure());
      });
  };

export const getReportCycleLagOptions =
  (lobId?: string | number, props = {}): AppThunk<Promise<unknown>> => dispatch => {
    dispatch(getReportCycleLagOptionsRequest());

    const params = {
      lobId,
      clientId,
      ...props,
    };

    return request
      .get('getReportCycleLagOptions', {
        params,
        convertResponse: false,
      })
      .then(({ data }) => {
        dispatch(getReportCycleLagOptionsSuccess(data));

        return data;
      })
      .catch(() => {
        dispatch(getReportCycleLagOptionsFailure());
      });
  };

export const getReportCycleAverage =
  (lobId?: string | number, props = {}): AppThunk<Promise<unknown>> => dispatch => {
    dispatch(getReportCycleAverageRequest());

    const params = {
      lobId,
      clientId,
      ...props,
    };

    return request
      .get('getReportCycleAvg', { params })
      .then(({ data }) => {
        dispatch(getReportCycleAverageSuccess(data));

        return data;
      })
      .catch(() => {
        dispatch(getReportCycleAverageFailure());
      });
  };

export const getModelAccuracyIdentifyLargeClaimsSpeed =
  (lobId?: string | number, props = {}): AppThunk<Promise<unknown>> => dispatch => {
    dispatch(getModelAccuracyIdentifyLargeClaimsSpeedRequest());

    const params = {
      lobId,
      clientId,
      ...props,
    };

    return request
      .get('getReportModelSpeed', { params })
      .then(({ data }) => {
        dispatch(getModelAccuracyIdentifyLargeClaimsSpeedSuccess(data));

        return data;
      })
      .catch(() => {
        dispatch(getModelAccuracyIdentifyLargeClaimsSpeedFailure());
      });
  };

export const getModelAccuracyClaimSizeByScoreBand =
  (
    // eslint-disable-next-line default-param-last
    props = {},
    config: AxiosRequestConfig,
    lobId?: string | number
  ): AppThunk<Promise<unknown>> => dispatch => {
    dispatch(getModelAccuracyClaimSizeByScoreBandRequest());

    const params = {
      lobId,
      clientId,
      ...props,
    };

    return request
      .get('getReportModelClaimSize', {
        params,
        ...config,
      })
      .then(({ data }) => {
        dispatch(getModelAccuracyClaimSizeByScoreBandSuccess(data));

        return data;
      })
      .catch(error => {
        if (axios.isCancel(error)) {
          dispatch(getModelAccuracyClaimSizeByScoreBandCancelled());
        } else {
          const { response: { data } } = error;

          dispatch(getModelAccuracyClaimSizeByScoreBandFailure(data));
        }
      });
  };

export const getClaimRiskAttributes =
(lobId?: string | number, props = {}): AppThunk<Promise<unknown>> => dispatch => {
  dispatch(getClaimRiskAttributesRequest());

  const params = {
    lobId,
    clientId,
    ...props,
  };

  return request
    .get('getReportClaimRiskAttributes', { params })
    .then(({ data }) => {
      dispatch(getClaimRiskAttributesSuccess(data));

      return data;
    })
    .catch(() => {
      dispatch(getClaimRiskAttributesFailure());
    });
};

export const getClaimRiskAttributesOptions =
(lobId?: string | number, props = {}): AppThunk<Promise<unknown>> => dispatch => {
  dispatch(getClaimRiskAttributesOptionsRequest());

  const params = {
    lobId,
    clientId,
    ...props,
  };

  return request
    .get('getReportClaimRiskAttributesOptions', {
      params,
      convertResponse: false,
    })
    .then(({ data }) => {
      dispatch(getClaimRiskAttributesOptionsSuccess(data));

      return data;
    })
    .catch(() => {
      dispatch(getClaimRiskAttributesOptionsFailure());
    });
};

export const getRiskAdjustedSeverityCurve =
(lobId?: string | number, props = {}): AppThunk<Promise<unknown>> => dispatch => {
  dispatch(getRiskAdjustedSeverityCurveRequest());

  const params = {
    clientId,
    lobId,
    ...props,
  };

  return request
    .get('getReportRiskAdjustedCurve', { params })
    .then(({ data }) => {
      dispatch(getRiskAdjustedSeverityCurveSuccess(data));

      return data;
    })
    .catch(() => {
      dispatch(getRiskAdjustedSeverityCurveFailure());
    });
};
export const getRiskAdjustedCost =
(lobId?: string | number, props = {}): AppThunk<Promise<unknown>> => dispatch => {
  dispatch(getRiskAdjustedCostRequest());

  const params = {
    clientId,
    lobId,
    ...props,
  };

  return request
    .get('getReportRiskAdjustedCost', { params })
    .then(({ data }) => {
      dispatch(getRiskAdjustedCostSuccess(data));

      return data;
    })
    .catch(() => {
      dispatch(getRiskAdjustedCostFailure());
    });
};

export const getModelAccuracyLargeClaims =
  (
    // eslint-disable-next-line default-param-last
    props = {},
    config: AxiosRequestConfig,
    lobId?: string | number
  ): AppThunk<Promise<unknown>> => dispatch => {
    dispatch(getModelAccuracyLargeClaimsRequest());

    const params = {
      clientId,
      lobId,
      ...props,
    };

    return request
      .get('getReportModelLargeClaims', {
        params,
        ...config,
      })
      .then(({ data }) => {
        dispatch(getModelAccuracyLargeClaimsSuccess(data));

        return data;
      })
      .catch(error => {
        if (axios.isCancel(error)) {
          dispatch(getModelAccuracyLargeClaimsCancelled());
        } else {
          const { response: { data } } = error;

          dispatch(getModelAccuracyLargeClaimsFailure(data));
        }
      });
  };

export const selectFinancialClaimCostTrend = (state: RootState) => (
  state.reports.financialClaimCostTrend
);
export const selectIsFinancialClaimCostTrendLoading = (state: RootState) => (
  state.reports.isFinancialClaimCostTrendLoading
);

export const selectFinancialClaimCostTrendOptions = (state: RootState) => ({
  value: state.reports.financialClaimCostTrendOptions.data ?
    prepareSelectOptions(state.reports.financialClaimCostTrendOptions.data.value) :
    [],
  category: state.reports.financialClaimCostTrendOptions.data ?
    prepareSelectOptions(state.reports.financialClaimCostTrendOptions.data.category) :
    [],
  calculatedBy: state.reports.financialClaimCostTrendOptions.data ?
    prepareSelectOptions(state.reports.financialClaimCostTrendOptions.data.calculatedBy) :
    [],
});
export const selectIsFinancialClaimCostTrendOptionsLoading = (state: RootState) => (
  state.reports.isFinancialClaimCostTrendLoading
);

export const selectFinancialClaimCostCategory = (state: RootState) => (
  state.reports.financialClaimCostCategory
);
export const selectIsFinancialClaimCostCategoryLoading = (state: RootState) => (
  state.reports.isFinancialClaimCostCategoryLoading
);

export const selectFinancialClaimCostCategoryOptions = (state: RootState) => ({
  value: state.reports.financialClaimCostCategoryOptions.data ?
    prepareSelectOptions(state.reports.financialClaimCostCategoryOptions.data.value) :
    [],
  category: state.reports.financialClaimCostCategoryOptions.data ?
    prepareSelectOptions(state.reports.financialClaimCostCategoryOptions.data.category) :
    [],
  calculatedBy: state.reports.financialClaimCostCategoryOptions.data ?
    prepareSelectOptions(state.reports.financialClaimCostCategoryOptions.data.calculatedBy) :
    [],
  claimStatus: state.reports.financialClaimCostCategoryOptions.data ?
    prepareSelectOptions(state.reports.financialClaimCostCategoryOptions.data.claimStatus) :
    [],
});
export const selectIsFinancialClaimCostCategoryLoadingOptions = (state: RootState) => (
  state.reports.isFinancialClaimCostCategoryOptionsLoading
);

export const selectCycleCloseLagByCloseDate = (state: RootState) => (
  state.reports.cycleCloseLagByCloseDate
);
export const selectIsCycleCloseLagByCloseDateLoading = (state: RootState) => (
  state.reports.isCycleCloseLagByCloseDateLoading
);

export const selectReportCycleLagOptions = (state: RootState) => ({
  claimAttribute: state.reports.reportCycleLagOptions.data ?
    prepareSelectOptions(state.reports.reportCycleLagOptions.data.claimAttribute) :
    [],
});
export const selectIsReportCycleLagOptionsLoading = (state: RootState) => (
  state.reports.isReportCycleLagOptionsLoading
);

export const selectReportCycleAverage = (state: RootState) => (
  state.reports.cycleTimeAverage
);
export const selectIsReportCycleAverageLoading = (state: RootState) => (
  state.reports.isCycleTimeAverageLoading
);

export const selectClaimRiskAttributes = (state: RootState) => (
  state.reports.claimRiskAttributes
);
export const selectIsClaimRiskAttributesLoading = (state: RootState) => (
  state.reports.isClaimRiskAttributesLoading
);

export const selectClaimRiskAttributesOptions = (state: RootState) => ({
  claimAttribute: state.reports.claimRiskAttributesOptions.data ?
    prepareSelectOptions(state.reports.claimRiskAttributesOptions.data.claimAttribute) :
    [],
  calculatedBy: state.reports.claimRiskAttributesOptions.data ?
    prepareSelectOptions(state.reports.claimRiskAttributesOptions.data.calculatedBy) :
    [],
});
export const selectIsClaimRiskAttributesOptionsLoading = (state: RootState) => (
  state.reports.isClaimRiskAttributesOptionsLoading
);

export const selectLargeClaimsIdentificationSpeed = (state: RootState) => (
  state.reports.largeClaimsIdentificationSpeed
);
export const selectIsLargeClaimsIdentificationSpeedLoading = (state: RootState) => (
  state.reports.isLargeClaimsIdentificationSpeedLoading
);

export const selectClaimSizeByScoreBand = (state: RootState) => (
  state.reports.claimSizeByScoreBand
);
export const selectIsClaimSizeByScoreBandLoading = (state: RootState) => (
  state.reports.isClaimSizeByScoreBandLoading
);
export const selectClaimSizeFiltersApplied = (state: RootState) => (
  state.reports.claimSizeFiltersApplied
);

export const selectModelAccuracyLargeClaims = (state: RootState) => (
  state.reports.modelAccuracyLargeClaims
);
export const selectIsModelAccuracyLargeClaimsLoading = (state: RootState) => (
  state.reports.isModelAccuracyLargeClaimsLoading
);
export const selectLargeClaimsFiltersApplied = (state: RootState) => (
  state.reports.largeClaimsFiltersApplied
);

export const selectRiskAdjustedSeverityCurve = (state: RootState) => (
  state.reports.riskAdjustedSeverityCurve
);
export const selectIsRiskAdjustedSeverityCurveLoading = (state: RootState) => (
  state.reports.isRiskAdjustedSeverityCurveLoading
);

export const selectRiskAdjustedCost = (state: RootState) => (
  state.reports.riskAdjustedCost
);
export const selectIsRiskAdjustedCostLoading = (state: RootState) => (
  state.reports.isRiskAdjustedCostLoading
);

export default reports.reducer;
