/* eslint-disable consistent-return */
import { createSlice } from '@reduxjs/toolkit';

import { Nullable } from '@/core/interfaces/common';
import { RootState } from '@/core/interfaces/store';

import {
  starClaimSuccess, unStarClaimSuccess,
} from '@/features/CT/ClaimList/store/slice/claims';

import { request } from '@/utils/request';

import { AppThunk } from '../store';

export type ClaimAdjustFlagsFormValues = {
  attorney: boolean;
  comorbidity: boolean;
  hospital: boolean;
  mri: boolean;
  surgery: boolean;
}

export type ClaimRescoreData = {
  score: number;
}

export type ClaimRescore = {
  metadata?: MetaData;
  data: ClaimRescoreData;
}

export type ClaimScoreInfo = {
  metadata?: MetaData;
  data: ClaimScoreInfoData;
}

export type ClaimScoreInfoData = {
  score: number;
  options: Options;
}

type Options = {
  surgery: ScoreInfoOption;
  attorney: ScoreInfoOption;
  mri: ScoreInfoOption;
  comorbidity: ScoreInfoOption;
  hospital: ScoreInfoOption;
}

export type ScoreInfoOption = {
  value: boolean;
  flagText: Array<string>;
}

type ClaimDetailsPredictions = {
  metadata?: MetaData;
  data: PredictionsData;
}

type PredictionsData = {
  predictions: Array<Predictions>;
  summary: Summary;
  paid: Paid;
  predictionHistory: PredictionHistory;
}

export type PredictionHistory = {
  score?: PredictionHistoryPart;
  lostTime?: PredictionHistoryPart;
  attorney?: PredictionHistoryPart;
  litigation?: PredictionHistoryPart;
}

export type PredictionHistoryPartItem = {
  epoch: string;
  value: string | number;
  alerts: Array<string>;
};

export type PredictionHistoryPart = { [key: string]: PredictionHistoryPartItem };

type Paid = {
  medical: Array<number | string>;
  indemnity: Array<number | string>;
  other: Array<number | string>;
}

type Summary = {
  status: Array<number | string>;
  state: Array<number | string>;
  part: Array<number | string>;
  nature: Array<number | string>;
  attorney: Array<boolean | number>;
  mri: Array<boolean | number>;
  surgery: Array<boolean | number>;
  comorbidities: Array<boolean | number>;
  claimantAge: Array<number>;
}

export enum PredictionTypes {
  SCORE = 'Score',
  ATTORNEY = 'Probability of Attorney',
  LOST_TIME = 'Probability of Lost Time',
  LITIGATION = 'Probability of Litigation',
}

type Predictions = {
  label: PredictionTypes;
  value: number;
}

export enum RiskLevel {
  MEDIUM = 'Medium',
  HIGH = 'High',
}

export type TmFlag = {
  flag: string;
  riskLevel: RiskLevel;
  startChar: number;
  endChar: number;
}

export type MetaData = {
  totalRecordCount?: number;
  pages?: number;
  currentPage?: number;
  message?: string;
  detailMessage?: string;
  lastUpdated?: string;
}

type ClaimDetailsGeneralData = {
  adjuster: Adjuster;
  accident: Accident;
  datePlace: DatePlace;
  claimantJob: ClaimantJob;
  claimant: Claimant;
}

type Claimant = {
  name: string;
  gender: string;
  dateOfBirth: string;
  age: number;
  ageStr: string;
}

type ClaimantJob = {
  occupation: string;
  department: string;
  dateOfHire: string;
}

type DatePlace = {
  dateOfLoss: string;
  dateReported: string;
  dateClosed?: any;
  dateReopened: string;
  state: string;
  zip: string;
}

type Accident = {
  lineType: string;
  claimType: string;
  causeInjury: string;
  bodyPart: string;
  resultOfInjury: string;
  description: string;
}

type Adjuster = {
  adjusterName: string;
  adjusterEmail: string;
}

type ClaimDetailsFinancial = {
  metadata?: MetaData;
  data: FinancialDetailsData;
}

type FinancialDetailsData = {
  table?: FinancialTable;
  graph?: FinancialGraph;
}

export type FinancialTable = {
  expense: Expense;
  medical: Expense;
  indemnity: Expense;
  total: Expense;
}

type Expense = {
  paid: string;
  reserve: string;
  incurred: string;
}

export type GraphPartItem = {
  epoch: string;
  paid: number;
  incurred: number;
};

export type GraphPart = { [key: string]: GraphPartItem };

export type FinancialGraph = {
  expense: GraphPart;
  medical: GraphPart;
  indemnity: GraphPart;
  total: GraphPart;
};

export type ClaimDetailsTopData = {
  claimId: number;
  claimNumber: string;
  alerts: Array<string>;
  score: string;
  claimStatus: string;
  riskCharacteristics: Array<string>;
  starred: boolean;
  tags: Array<string>;
}

export type ClaimDetailsTop = {
  metadata?: MetaData;
  data: ClaimDetailsTopData;
  isDiagnosisKnown?: boolean;
}

export type ClaimDetailsGeneral = {
  metadata?: MetaData;
  data: ClaimDetailsGeneralData;
  isDiagnosisKnown?: boolean;
}

export type ClaimDetailsState = {
  topDetails: ClaimDetailsTop;
  general: ClaimDetailsGeneral;
  financial: ClaimDetailsFinancial;
  isFinancialLoading: boolean;
  predictions: ClaimDetailsPredictions;
  claimScoreInfo: ClaimScoreInfo;
  claimRescore: ClaimRescore;
  error: Nullable<string>;
  isLoading: boolean;
  isGeneralLoading: boolean;
  isPredictionsLoading: boolean;
  isClaimScoreInfoLoading: boolean;
  isClaimRescoreLoading: boolean;
}

const initialState: ClaimDetailsState = {
  topDetails: {
    data: {
      alerts: [],
      claimId: 0,
      claimNumber: '',
      claimStatus: '',
      score: '',
      riskCharacteristics: [],
      starred: false,
      tags: [],
    },
  },
  general: {
    data: {
      accident: {
        lineType: '',
        claimType: '',
        causeInjury: '',
        bodyPart: '',
        resultOfInjury: '',
        description: '',
      },
      adjuster: {
        adjusterEmail: '',
        adjusterName: '',
      },
      claimant: {
        age: 0,
        ageStr: '',
        dateOfBirth: '',
        gender: '',
        name: '',
      },
      claimantJob: {
        dateOfHire: '',
        department: '',
        occupation: '',
      },
      datePlace: {
        dateOfLoss: '',
        dateReopened: '',
        dateReported: '',
        state: '',
        zip: '',
        dateClosed: '',
      },
    },
  },
  financial: {
    data: {
      graph: undefined,
      table: undefined,
    },
  },
  predictions: {
    data: {
      paid: {
        indemnity: [],
        medical: [],
        other: [],
      },
      predictionHistory: {
        attorney: undefined,
        litigation: undefined,
        lostTime: undefined,
        score: undefined,
      },
      predictions: [],
      summary: {
        attorney: [],
        claimantAge: [],
        comorbidities: [],
        mri: [],
        nature: [],
        part: [],
        state: [],
        status: [],
        surgery: [],
      },
    },
  },
  claimScoreInfo: {
    data: {
      options: {
        attorney: {
          flagText: [],
          value: false,
        },
        comorbidity: {
          flagText: [],
          value: false,
        },
        hospital: {
          flagText: [],
          value: false,
        },
        mri: {
          flagText: [],
          value: false,
        },
        surgery: {
          flagText: [],
          value: false,
        },
      },
      score: 0,
    },
  },
  claimRescore: { data: { score: 0 } },
  error: null,
  isLoading: false,
  isGeneralLoading: false,
  isFinancialLoading: false,
  isPredictionsLoading: false,
  isClaimScoreInfoLoading: false,
  isClaimRescoreLoading: false,
};

const claimDetails = createSlice({
  name: 'claimDetails',
  initialState,
  reducers: {
    getClaimDetailsTopRequest(state) {
      state.topDetails = initialState.topDetails;
      state.isLoading = true;
      state.error = null;
    },
    getClaimDetailsTopSuccess(state, action) {
      state.topDetails = action.payload;
      state.isLoading = false;
    },
    getClaimDetailsTopFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getClaimDetailsGeneralRequest(state) {
      state.general = initialState.general;
      state.isGeneralLoading = true;
      state.error = null;
    },
    getClaimDetailsGeneralSuccess(state, action) {
      state.general = action.payload;
      state.isGeneralLoading = false;
    },
    getClaimDetailsGeneralFailure(state, action) {
      state.isGeneralLoading = false;
    },
    getClaimDetailsFinancialRequest(state) {
      state.financial = initialState.financial;
      state.isFinancialLoading = true;
      state.error = null;
    },
    getClaimDetailsFinancialSuccess(state, action) {
      state.financial = action.payload;
      state.isFinancialLoading = false;
    },
    getClaimDetailsFinancialFailure(state, action) {
      state.isFinancialLoading = false;
      state.error = action.payload;
    },
    getClaimDetailsPredictionsRequest(state) {
      state.predictions = initialState.predictions;
      state.isPredictionsLoading = true;
      state.error = null;
    },
    getClaimDetailsPredictionsSuccess(state, action) {
      state.predictions = action.payload;
      state.isPredictionsLoading = false;
    },
    getClaimDetailsPredictionsFailure(state, action) {
      state.isPredictionsLoading = false;
      state.error = action.payload;
    },
    getClaimScoreInfoRequest(state) {
      state.claimScoreInfo = initialState.claimScoreInfo;
      state.isClaimScoreInfoLoading = true;
      state.error = null;
    },
    getClaimScoreInfoSuccess(state, action) {
      state.claimScoreInfo = action.payload;
      state.isClaimScoreInfoLoading = false;
    },
    getClaimScoreInfoFailure(state, action) {
      state.isClaimScoreInfoLoading = false;
      state.error = action.payload;
    },
    getClaimRescoreRequest(state) {
      state.claimRescore = initialState.claimRescore;
      state.isClaimRescoreLoading = true;
      state.error = null;
    },
    getClaimRescoreSuccess(state, action) {
      state.claimRescore = action.payload;
      state.isClaimRescoreLoading = false;
    },
    getClaimRescoreFailure(state, action) {
      state.isClaimRescoreLoading = false;
      state.error = action.payload;
    },
  },
  extraReducers: {
    [starClaimSuccess.type]: (state, action) => {
      if (state.topDetails?.data) {
        state.topDetails.data.starred = true;
      }
    },
    [unStarClaimSuccess.type]: (state, action) => {
      if (state.topDetails?.data) {
        state.topDetails.data.starred = false;
      }
    },
  },
});

export const {
  getClaimDetailsTopFailure,
  getClaimDetailsTopRequest,
  getClaimDetailsTopSuccess,
  getClaimDetailsGeneralFailure,
  getClaimDetailsGeneralRequest,
  getClaimDetailsGeneralSuccess,
  getClaimDetailsFinancialRequest,
  getClaimDetailsFinancialSuccess,
  getClaimDetailsFinancialFailure,
  getClaimDetailsPredictionsFailure,
  getClaimDetailsPredictionsRequest,
  getClaimDetailsPredictionsSuccess,
  getClaimScoreInfoFailure,
  getClaimScoreInfoRequest,
  getClaimScoreInfoSuccess,
  getClaimRescoreFailure,
  getClaimRescoreRequest,
  getClaimRescoreSuccess,
} = claimDetails.actions;

export const getClaimDetailsTop =
  (claimId: number, userEmail?: string): AppThunk<Promise<unknown>> => async dispatch => {
    dispatch(getClaimDetailsTopRequest());

    const params = {
      claimId,
      userEmail,
    };

    try {
      const { data } = await request.get('claimDetailTop', { params });

      dispatch(getClaimDetailsTopSuccess(data));

      return data;
    } catch ({ response: { data } }) {
      dispatch(getClaimDetailsTopFailure(data));
    }
  };

export const getClaimDetailsGeneral =
  (claimId: number): AppThunk<Promise<unknown>> => async dispatch => {
    dispatch(getClaimDetailsGeneralRequest());

    const params = { claimId };

    try {
      const { data } = await request.get('claimDetailGeneral', { params });

      dispatch(getClaimDetailsGeneralSuccess(data));

      return data;
    } catch ({ response: { data } }) {
      dispatch(getClaimDetailsGeneralFailure(data));
    }
  };

export const getClaimDetailsFinancial =
  (claimId: number): AppThunk<Promise<unknown>> => async dispatch => {
    dispatch(getClaimDetailsFinancialRequest());

    const params = { claimId };

    try {
      const { data } = await request.get('claimDetailFinancial', { params });

      dispatch(getClaimDetailsFinancialSuccess(data));

      return data;
    } catch ({ response: { data } }) {
      dispatch(getClaimDetailsFinancialFailure(data));
    }
  };

export const getClaimDetailsPredictions =
  (claimId: number, props = {}): AppThunk<Promise<unknown>> => async dispatch => {
    dispatch(getClaimDetailsPredictionsRequest());

    const params = {
      ...props,
      claimId,
    };

    try {
      const { data } = await request.get('claimDetailPrediction', { params });

      dispatch(getClaimDetailsPredictionsSuccess(data));

      return data;
    } catch ({ response: { data } }) {
      dispatch(getClaimDetailsPredictionsFailure(data));
    }
  };

export const getClaimScoreInfo =
  (claimId: string, props = {}): AppThunk<Promise<unknown>> => async dispatch => {
    dispatch(getClaimScoreInfoRequest());

    const params = {
      ...props,
      claimId,
    };

    try {
      const { data } = await request.get('claimScoreInfo', { params });

      dispatch(getClaimScoreInfoSuccess(data));

      return data;
    } catch ({ response: { data } }) {
      dispatch(getClaimScoreInfoFailure(data));
    }
  };

export const getClaimRescore =
  (
    claimId: string,
    formValues: ClaimAdjustFlagsFormValues
  ): AppThunk<Promise<unknown>> => async dispatch => {
    dispatch(getClaimRescoreRequest());

    const params = {
      ...formValues,
      claimId,
    };

    try {
      const { data } = await request.get('claimRescore', { params });

      dispatch(getClaimRescoreSuccess(data));

      return data;
    } catch ({ response: { data } }) {
      dispatch(getClaimRescoreFailure(data));
    }
  };

export const selectClaimDetailsTop = (state: RootState) => state.claimDetails.topDetails;
export const selectIsLoading = (state: RootState) => state.claimDetails.isLoading;

export const selectClaimDetailsGeneral = (state: RootState) => (
  state.claimDetails.general
);
export const selectIsGeneralLoading = (state: RootState) => state.claimDetails.isGeneralLoading;

export const selectClaimDetailsFinancial = (state: RootState) => state.claimDetails.financial;
export const selectIsFinancialLoading = (state: RootState) => state.claimDetails.isFinancialLoading;

export const selectClaimDetailsPredictions = (state: RootState) => (
  state.claimDetails.predictions
);
export const selectIsPredictionsLoading = (state: RootState) => (
  state.claimDetails.isPredictionsLoading
);

export const selectClaimScoreInfo = (state: RootState) => state.claimDetails.claimScoreInfo;
export const selectIsClaimScoreInfoLoading = (state: RootState) => (
  state.claimDetails.isClaimScoreInfoLoading
);

export const selectClaimRescore = (state: RootState) => state.claimDetails.claimRescore;
export const selectIsClaimRescoreLoading = (state: RootState) => (
  state.claimDetails.isClaimRescoreLoading
);

export default claimDetails.reducer;
