import styled, { css } from 'styled-components';

import { spacing } from '@/core/styles/mixins/spacing';

type BodyTextProps = {
  large?: boolean;
  long?: boolean;
  bottomSpacing?: number;
};

export const BodyText = styled.span<BodyTextProps>`
  display: ${({ bottomSpacing }) => (bottomSpacing ? 'block' : 'inline')};
  margin-bottom: ${({ bottomSpacing }) => spacing(bottomSpacing || 0)};
  font-size: ${({ large }) => (large ? '16px' : '14px')};
  line-height: ${({ large }) => (large ? '22px' : '18px')};
  letter-spacing: ${({ large }) => (large ? '0px' : '0.16px')};

  ${({
    large,
    long,
  }) => long && large && css`
    line-height: 24px;
  `}
`;
