import styled from 'styled-components';

export const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  border-bottom: 1px solid ${({ theme }) => theme.semanticColors.ui[6]};
  padding: 15px;
  background-color: ${({ theme }) => theme.semanticColors.ui[5]};
  color: ${({ theme }) => theme.semanticColors.text.inverse.primary};
`;

export const StyledIconLink = styled.a`
  color: ${({ theme }) => theme.semanticColors.text.inverse.primary};
  font-size: 20px;
`;
