import React, {
  useEffect,
  useState,
} from 'react';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import {
  ExpandMore,
  HelpOutline,
  LocalHospital,
  Toc,
} from '@mui/icons-material';
import { useSelector } from 'react-redux';

import { routePaths } from '@/core/constants/routes';
import {
  isMultipleLineOfBusinessLoading, selectSelectedLineOfBusiness,
} from '@/core/store/reducers/staticContent';

import { Select } from '@/features/MultiLine';

import {
  AccordionMenuItem,
  AccordionMenuSubItem,
  Container,
  IconContainer,
  Link,
  MenuList,
  NonAccordionItem,
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
  StyledDivider,
  StyledSkeleton,
} from './Sidebar.styles';

const claimsSubItems = [
  {
    icon: null,
    label: 'CT Overview',
    path: routePaths.claimsTriage.overview,
  },
  {
    icon: null,
    label: 'CT Claims',
    path: routePaths.claimsTriage.claims,
  },
  {
    icon: null,
    label: 'CT Reports',
    path: routePaths.claimsTriage.reports,
  },
];

const medBenchSubItems = [
  {
    icon: null,
    label: 'MB Overview',
    path: routePaths.medBench.overview,
  },
  {
    icon: null,
    label: 'MB Claims',
    path: routePaths.medBench.claims,
  },
  {
    icon: null,
    label: 'Providers',
    path: routePaths.medBench.providers,
  },
  {
    icon: null,
    label: 'Treatment Benchmarks',
    path: routePaths.medBench.treatment,
  },
  {
    icon: null,
    label: 'MB Reports',
    path: routePaths.medBench.reports,
  },
];

const menuItems = [
  {
    divider: true,
  },
  {
    icon: <Toc
      fontSize="inherit"
      htmlColor="inherit"
    />,
    label: 'Claims Triage',
    path: null,
    submenuName: 'claimsTriage',
    subItems: claimsSubItems,
  },
  {
    icon: <LocalHospital
      fontSize="inherit"
      htmlColor="inherit"
    />,
    label: 'Medical Benchmarking',
    path: null,
    submenuName: 'medBench',
    subItems: medBenchSubItems,
  },
  {
    divider: true,
  },
  {
    icon: <HelpOutline
      fontSize="inherit"
      htmlColor="inherit"
    />,
    label: 'Help',
    path: routePaths.help,
  },
];

export const Sidebar = () => {
  const selectedLineOfbusiness = useSelector(selectSelectedLineOfBusiness);
  const isLineOfBusinessLoading = useSelector(isMultipleLineOfBusinessLoading);
  const {
    asPath, route,
  } = useRouter();
  const [
    expandedSubmenus,
    setExpandedSubmenus,
  ] = useState<Array<string>>([]);

  const addExpandedSubmenu = (submenu = '') => setExpandedSubmenus(prevExpandedSubmenus => [
    ...prevExpandedSubmenus,
    submenu,
  ]);

  const removeExpandedSubmenu = (submenu = '') => setExpandedSubmenus(prevExpandedSubmenus => prevExpandedSubmenus.filter(
    expandedSubmenu => expandedSubmenu !== submenu
  ));

  const triggerExpandedSubmenu = (submenu = '') => {
    if (expandedSubmenus.includes(submenu)) {
      removeExpandedSubmenu(submenu);
    } else {
      addExpandedSubmenu(submenu);
    }
  };

  useEffect(() => {
    menuItems
      .filter(item => item.submenuName && item.subItems)
      .forEach(item => {
        if (item?.subItems?.some(subItem => asPath.includes(subItem?.path))) {
          addExpandedSubmenu(item.submenuName);
        } else {
          removeExpandedSubmenu(item.submenuName);
        }
      });
  }, [asPath]);

  return (
    <Container>
      <Select />
      <MenuList>
        {menuItems.map(
          ({
            divider,
            icon,
            label,
            submenuName,
            subItems,
            path,
          }) => (divider ?
            (
              <StyledDivider
                $marginTop={2}
                $marginBottom={2}
              />
            ) :
            (
              (subItems &&
                  (
                  <>
                    {!isLineOfBusinessLoading && selectedLineOfbusiness?.accessList?.some(
                      accessList => accessList.accessName === label
                    ) && (
                    <StyledAccordion
                      disableGutters
                      elevation={0}
                      square
                      expanded={
                    !!submenuName && expandedSubmenus.includes(submenuName)
                  }
                      onChange={() => triggerExpandedSubmenu(submenuName)}
                    >
                      <StyledAccordionSummary
                        expandIcon={(
                          <ExpandMore
                            fontSize="small"
                            color="inherit"
                          />
                    )}
                      >
                        <AccordionMenuItem>
                          <IconContainer>{icon}</IconContainer>
                          {label}
                        </AccordionMenuItem>
                      </StyledAccordionSummary>
                      <StyledAccordionDetails>
                        {subItems?.map(subItem => (
                          <NextLink
                            key={subItem.path}
                            href={subItem.path}
                            passHref
                          >
                            <Link>
                              <AccordionMenuSubItem
                                isActive={
                                  subItem.path.split('/').length > 2 ?
                                    asPath.includes(subItem.path, 0) :
                                    route === subItem.path
                                }
                              >
                                {subItem.label}
                              </AccordionMenuSubItem>
                            </Link>
                          </NextLink>
                        ))}
                      </StyledAccordionDetails>
                    </StyledAccordion>
                    )}

                    {isLineOfBusinessLoading && (
                    <StyledAccordionSummary>
                      <AccordionMenuItem>
                        <IconContainer>
                          <StyledSkeleton />
                        </IconContainer>
                        <StyledSkeleton variant="text" />
                      </AccordionMenuItem>
                    </StyledAccordionSummary>
                    )}
                  </>
                  )
              ) || (!subItems && path && (
                <NextLink
                  key={path}
                  href={path}
                  passHref
                >

                  <Link>
                    <NonAccordionItem
                      isActive={
                        path.split('/').length > 2 ?
                          asPath.includes(path, 0) :
                          route === path
                      }
                    >
                      <AccordionMenuItem>
                        <IconContainer>{icon}</IconContainer>
                        {label}
                      </AccordionMenuItem>
                    </NonAccordionItem>
                  </Link>
                </NextLink>
              ))
            ))
        )}
      </MenuList>
    </Container>
  );
};
