import styled from 'styled-components';

import { spacing } from '@/core/styles/mixins/spacing';

export const Divider = styled.div<{ $marginTop?: number; $marginBottom?: number }>`
  width: 100%;
  height: 1px;
  margin-top: ${({ $marginTop }) => ($marginTop ? spacing($marginTop) : 'auto')};
  margin-bottom: ${({ $marginBottom }) => ($marginBottom ? spacing($marginBottom) : 'auto')};
  background-color: ${({ theme }) => theme.colors.slate};
`;
