import { routePaths } from './routes';

export const SHORT_DATE_FORMAT = 'MM/DD/YYYY' as const;
export const API_DATE_FORMAT = 'YYYY-MM-DD' as const;
export const CHART_DATE_FORMAT = 'MM/YYYY' as const;
export const CHART_DATE_FORMAT_MONTH_NAME = 'MMMM YYYY' as const;

export const REFERENCE_CONTENT_FULL_WIDTH = 1078;

export const ALL_OPTION = {
  value: 'all',
  label: 'All',
};

export const defaultOption = {
  value: '',
  label: '',
};

export const defaultDataSource = {
  value: 'Group Health',
  label: 'Group Health',
};

export const lineOfBusinessURL = {
  'Claims Triage': routePaths.claimsTriage.overview,
  'Medical Benchmarking': routePaths.medBench.overview,
  'Adjuster Evaluation': routePaths.home,
  Help: routePaths.help,
};

export enum LineOfBusinessAccessNames {
  CLAIMS_TRIAGE = 'Claims Triage',
  MEDICAL_BENCHMARKING = 'Medical Benchmarking'
}

