import {
  createAction,
} from '@reduxjs/toolkit';

import { getActionPrefix } from '@/utils/helpers';

const actionPrefix = getActionPrefix('config');

export const updateLobId = createAction(`${actionPrefix}/updateLobId`, id => ({ payload: id }));

const allStorage = () => {
  const values: Array<string> = [];
  const keys = Object.keys(localStorage);

  keys.forEach(item => {
    const storageItem = localStorage.getItem(item);

    if (storageItem) {
      values.push(storageItem);
    }
  });

  return Object.values(localStorage).map(item => JSON.parse(item));
};

export const initializeApp = createAction(`${actionPrefix}/initializeApp`, () => {
  const items = allStorage();

  const values = items.reduce((acc, curr) => ({
    ...acc,
    ...curr,
  }), []);

  return {
    payload: {
      data: {
        email: values?.username,
        name: values?.name,
      },
    },
  };
});
