import {
  createSlice,
} from '@reduxjs/toolkit';

import { RootState } from '@/core/interfaces/store';
import { Nullable } from '@/core/interfaces/common';

import { TopDetailsData } from '@/features/MB/ClaimDetails/interfaces';

import { request } from '@/utils/request';

import { AppThunk } from '../store';

export type MedicalBenchmarkingState = {
  mbTopDetails: TopDetailsData;
  isLoading: boolean;
  error: Nullable<string>;
}

const initMBTopState: TopDetailsData = {
  alerts: [
    {
      alert: '',
      date: new Date(),
    },
  ],
  starred: false,
  benchmarksAllowedCost: 0,
  claimNumber: '',
  benchmarksAllowedCostPerc: 0,
  benchmarksUnitCost: 0,
  lastUpdated: new Date(),
  benchmarksUtilization: 0,
  claimId: '',
  condition: '',
  tags: [],
  providerRating: '',
};

const initialState: MedicalBenchmarkingState = {
  mbTopDetails: initMBTopState,
  isLoading: false,
  error: null,
};

const medicalBenchmarking = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    getMBClaimDetailsTopRequest(state) {
      state.mbTopDetails = initMBTopState;
      state.isLoading = true;
      state.error = null;
    },
    getMBClaimDetailsTopSuccess(state, action) {
      state.mbTopDetails = action.payload;
      state.isLoading = false;
    },
    getMBClaimDetailsTopFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  getMBClaimDetailsTopFailure,
  getMBClaimDetailsTopRequest,
  getMBClaimDetailsTopSuccess,
} = medicalBenchmarking.actions;

export const getMBClaimsDetailsTop =
  (claimId: number, userEmail?: string): AppThunk<Promise<unknown>> => async dispatch => {
    dispatch(getMBClaimDetailsTopRequest());

    const params = {
      claimId,
      userEmail,
    };

    try {
      const { data } = await request.get('medBenchClaimDetailTop', { params });

      dispatch(getMBClaimDetailsTopSuccess(data));

      return data;
    } catch ({ response: { data } }) {
      dispatch(getMBClaimDetailsTopFailure(data));
    }
  };

export const selectMBClaimTopDetails = (state: RootState) => state.medicalBenchmarking.mbTopDetails;
export const selectMBClaimTopDetailsLoading =
  (state: RootState) => state.medicalBenchmarking.isLoading;

export default medicalBenchmarking.reducer;
