import { ComponentPropsWithRef } from 'react';

const BASE_PATH = process.env.NEXT_PUBLIC_BASE_PATH;

export type ImageProps = ComponentPropsWithRef<'img'> & Required<Pick<ComponentPropsWithRef<'img'>, 'alt'>>;

export const Image = ({
  src,
  alt,
  ...props
}: ImageProps) => (
  <img
    src={`${BASE_PATH || ''}${src}`}
    alt={alt}
    {...props}
  />
);
