import styled, { css } from 'styled-components';

import { H5 } from '@/core/components/typography';
import { Pill } from '@/core/components/Pill';
import { spacing } from '@/core/styles/mixins/spacing';

export const LineWrapper = styled.section`
  display: grid;
  grid-template-columns: 1.4fr 1fr 0.8fr;
  gap: 20px;
  grid-auto-flow: row;
  width: 100%;
`;

export const StyledPill = styled(Pill)`
  display: block;
  margin: 0 ${spacing(1)} 0 0;
`;

export const LinkWrapper = styled.section<{ $isPointer?: boolean }>`
  display: flex;
  justify-content: end;
  align-items: center;

  ${({ $isPointer }) => $isPointer && css`
    cursor: pointer;
  `}
`;

export const Wrapper = styled.section`
  display: flex;
  justify-content: start;
  align-items: center;
  width: 180px;
`;

export const Number = styled(H5)`
  margin: 0 ${spacing(0.5)};
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
`;

export const Text = styled(H5)`
  margin-right: ${spacing(1.125)};
  color: ${({ theme }) => theme.colors.milimanBlue20};
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
`;

export const HeaderText = styled(H5)`
  min-width: 180px;
`;
